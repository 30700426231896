import React, { useState, useEffect } from "react";
import { ButtonBase } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { doc, onSnapshot, setDoc, deleteDoc } from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import { firestoreDb } from "../../../components/firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import copy from "copy-to-clipboard";
import aa from "search-insights";

import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Avatar from "@mui/material/Avatar";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

import Tooltip from "@mui/material/Tooltip";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import ReportOutlined from "@mui/icons-material/ReportOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";

import FacebookLogo from "../../../components/ui/img/socials/FacebookLogo";
import XLogo from "../../../components/ui/img/socials/Xlogo";
import InstagramLogo from "../../../components/ui/img/socials/InstagramLogo";
import MediumLogo from "../../../components/ui/img/socials/MediumLogo";
import TelegramLogo from "../../../components/ui/img/socials/TelegramLogo";
import SubstackLogo from "../../../components/ui/img/socials/SubstackLogo";

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../components/firebase/analytics";
import moment from "moment";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useMediumState,
  useRss3State,
  useRssState,
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
  useExploreSortState,
  useAuthStates,
} from "../../../components/utils/globalStates";

import {
  removeUrlPrefix,
  removeTwitterPrefix,
  prependHTTP,
} from "../../../components/utils/parsers";
import NewsOuter from "./NewsOuter";
import AdminListingDialog from "../../admin/dialogs/AdminListingDialog";
import ListingPageReviewPreviews from "./reviews/ListingPageReviewPreviews";
import ListingPageInfo from "../info/ListingPageInfo";
import RatingItem from "../components/RatingItem";
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import {
  standardDialogRoot,
  standardDialogPaper,
  standardDialogTitle,
} from "../../../components/theme/globalMuiTheme";
import "./ListingPage.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

moment().format();

export default function ListingsPage() {
  const [user] = useAuthState(firebaseAuth);
  const authStates = useAuthStates((state) => state);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { listingId } = useParams();
  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState();
  const snackbarStates = useSnackbarState();
  const rssStates = useRssState();
  const rss3States = useRss3State();
  const mediumStates = useMediumState();
  const headerSettings = useHeaderSettingsState();
  const tabSettings = useTabSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const exploreSortState = useExploreSortState();
  const [listingData, setListingData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState("reviews");
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);
  const [isTopExpanded, setIsTopExpanded] = useState(true);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [listingReportDialog, setListingReportDialog] = useState(false);
  const [adminEditDialog, setAdminEditDialog] = useState(false);
  const [reasonString, setReasonString] = useState("");
  const [reasonArray, setReasonArray] = useState<string[]>([]);
  const [isSendingListingReport, setIsSendingListingReport] = useState(false);
  const [authorName, setAuthorName] = useState("");

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setReasonArray([...reasonArray, event.target.value]);
    } else {
      setReasonArray(
        reasonArray.filter((reason) => reason !== event.target.value)
      );
    }
  };

  // Update reasonString based on reasonArray
  useEffect(() => {
    setReasonString(reasonArray.join(","));
  }, [reasonArray]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onListingReportCancel = () => {
    if (isSendingListingReport) return;
    setReasonString("");
    setReasonArray([]);
    setListingReportDialog(false);
  };

  // Handle user report submit
  const onListingReportSubmit = async () => {
    if (isSendingListingReport) return;
    if (listingData === null || listingData === undefined) return;
    if (user?.uid === undefined || user?.uid === null) return;
    if (authStates.userData === null || authStates.userData === undefined)
      return;
    setIsSendingListingReport(true);
    if (reasonString === "") {
      snackbarError.setMessage("Reason is required");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setIsSendingListingReport(false);
      return;
    }
    const callable = httpsCallable(firebaseFunctions, "listings-new_report");
    try {
      await callable({
        reason: reasonString,
        id: listingId,
        token: await user?.getIdToken(),
        reporterName: authStates.userData.displayName,
        name: listingData.name,
      });
      setListingReportDialog(false);
      setIsSendingListingReport(false);
      snackbarStates.setMessage(listingData.name + " reported");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (e) {
      console.error(e);
      setIsSendingListingReport(false);
      snackbarError.setMessage("Error reporting user, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  const onFollowClick = async () => {
    if (listingData === null || listingData === undefined) return;
    if (user?.uid === undefined) return;
    if (isFollowingLoading === true) return;
    setIsFollowingLoading(true);
    if (isFollowing === true) {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userBookmarks",
        user?.uid + "--" + listingId
      );
      try {
        await deleteDoc(followingRef);
        setIsFollowingLoading(false);
        snackbarStates.setMessage(listingData.name + " removed from bookmarks");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage(
          `Error removing ${listingData.name} from bookmarks, please try again`
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }

      // Send event to algolia
      let isFound = false;
      for (let i = 0; i < exploreSortState.results.length; i++) {
        if (exploreSortState.results[i].id === listingData.id) {
          isFound = true;
          break;
        }
      }
      if (isFound && exploreSortState.results[0].searchId !== undefined) {
        try {
          await aa("convertedObjectIDsAfterSearch", {
            index: "gath3r_listings",
            eventName: "Bookmark removed from listing page after Search",
            queryID: exploreSortState.results[0].searchId,
            objectIDs: [listingData.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      } else {
        try {
          await aa("convertedObjectIDs", {
            index: "gath3r_listings",
            eventName: "Bookmark removed from listing page",
            objectIDs: [listingData.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      }
    } else {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userBookmarks",
        user?.uid + "--" + listingData.id
      );
      try {
        await setDoc(followingRef, {
          following: listingData.id,
          follower: user?.uid,
          followedAt: Date.now(),
          name: listingData.name,
          category: listingData.category,
        });
        setIsFollowingLoading(false);
        snackbarStates.setMessage(listingData.name + " added to bookmarks");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage(
          `Error adding ${listingData.name} to bookmarks, please try again`
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }

      // Send event to algolia
      let isFound = false;
      for (let i = 0; i < exploreSortState.results.length; i++) {
        if (exploreSortState.results[i].id === listingData.id) {
          isFound = true;
          break;
        }
      }
      if (isFound && exploreSortState.results[0].searchId !== undefined) {
        try {
          await aa("convertedObjectIDsAfterSearch", {
            index: "gath3r_listings",
            eventName: "Bookmark added in listing page after Search",
            queryID: exploreSortState.results[0].searchId,
            objectIDs: [listingData.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      } else {
        try {
          await aa("convertedObjectIDs", {
            index: "gath3r_listings",
            eventName: "Bookmark added in listing page",
            objectIDs: [listingData.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      }
    }
  };

  // Check if user is following
  useEffect(() => {
    if (user?.uid === undefined) return;
    if (listingData === null || listingData === undefined) return;
    if (
      listingData.id === null ||
      listingData.id === undefined ||
      listingData.id === ""
    ) {
      return;
    }
    if (isFollowingLoading === true) return;
    setIsFollowingLoading(true);
    const followingRef = doc(
      firestoreDb,
      "follow",
      user?.uid,
      "userBookmarks",
      user?.uid + "--" + listingData.id
    );
    const unsubscribe = onSnapshot(followingRef, (doc) => {
      if (doc.exists()) {
        setIsFollowing(true);
        setIsFollowingLoading(false);
      } else {
        setIsFollowing(false);
        setIsFollowingLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user?.uid, listingData]);

  const onShareClick = async () => {
    copy(`https://app.gath3r.co/listings/page/${listingId}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);

    // Send event to algolia
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listingData.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && exploreSortState.results[0].searchId !== undefined) {
      console.log("convertedObjectIDsAfterSearch");
      try {
        await aa("convertedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Listing share click after Search",
          queryID: exploreSortState.results[0].searchId,
          objectIDs: [listingData.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      console.log("convertedObjectIDs");
      try {
        await aa("convertedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Listing share click",
          objectIDs: [listingData.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  // Fetch listing data from listings collection
  useEffect(() => {
    if (listingId === undefined || listingId === "" || listingId === null) {
      snackbarError.setMessage("Listing not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/explore");
    }

    setIsLoading(true);
    const listingRef = doc(firestoreDb, "listings", listingId);
    const unsubscribe = onSnapshot(listingRef, (doc) => {
      if (doc.exists()) {
        setListingData(doc.data());
        setIsLoading(false);
        console.log(doc.data());
      } else {
        snackbarError.setMessage("Listing not found");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return navigate("/explore");
      }
    });
    return () => {
      unsubscribe();
    };
  }, [listingId]);

  // Add visited listing to local storage, max 10

  const addVisitedListing = () => {
    let visitedListings: any[] = [];
    if (localStorage.getItem("visitedListings") !== null) {
      visitedListings = JSON.parse(localStorage.getItem("visitedListings")!);
    }
    if (visitedListings.length > 9) {
      visitedListings.shift();
    }
    // Check if listing is already in visitedListings
    for (let i = 0; i < visitedListings.length; i++) {
      const tempVisitedListing = visitedListings[i];
      if (tempVisitedListing.id === listingData.id) {
        visitedListings.splice(i, 1);
      }
    }
    visitedListings.unshift({
      name: listingData.name,
      id: listingData.id,
      category: listingData.category,
      visited: Date.now(),
    });
    localStorage.setItem("visitedListings", JSON.stringify(visitedListings));
  };

  useEffect(() => {
    if (listingData !== null && listingData !== undefined) {
      addVisitedListing();
    }
  }, [listingData]);

  useEffect(() => {
    if (listingData !== null && listingData !== undefined) {
      let timeOutInit: any = null;

      const timeoutFunc = () => {
        timeOutInit = setTimeout(() => {
          logEvent(firebaseAnalytics, "listing_click", {
            listing_id: listingData.id,
          });
        }, 3000);
      };
      timeoutFunc();
      return () => {
        clearTimeout(timeOutInit);
      };
    }
  }, [listingData]);

  // Reset news data on listing change
  useEffect(() => {
    rssStates.setListings([]);
    rssStates.setIsFetched(false);
    rss3States.setListings([]);
    rss3States.setIsFetched(false);
    mediumStates.setListings([]);
    mediumStates.setIsFetched(false);
  }, [listingId]);

  useEffect(() => {
    headerSettings.setIsBackButtonOpen(true);
    tabSettings.setColor(globalTheme.backgroundMainBeige.color);
    tabSettings.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettings.setIsBackButtonOpen(false);
      tabSettings.setColor(globalTheme.backgroundMainYellow.color);
      tabSettings.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettings.setLogoBGColor(globalTheme.backgroundMainYellow.color);
      headerSettings.setLogoColor(globalTheme.backgroundMainOffYellow.color);
      headerSettings.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const onTagClick = async (tag: string) => {
    exploreSortState.setSearch(tag);
    exploreSortState.setSortType("relevance");
    navigate(`/explore`);

    // Send event to algolia
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listingData.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && exploreSortState.results[0].searchId !== undefined) {
      try {
        await aa("convertedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Clicked tag after Search: " + tag,
          queryID: exploreSortState.results[0].searchId,
          objectIDs: [listingData.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      try {
        await aa("convertedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Clicked tag: " + tag,
          objectIDs: [listingData.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  // Fetch author name

  useEffect(() => {
    if (listingData !== null && listingData !== undefined) {
      if (
        listingData.createdBy !== null &&
        listingData.createdBy !== undefined &&
        listingData.createdBy !== ""
      ) {
        const authorRef = doc(
          firestoreDb,
          "publicUserInfo",
          listingData.createdBy
        );
        const unsubscribe = onSnapshot(authorRef, (doc) => {
          if (doc.exists()) {
            setAuthorName(doc.data().displayName);
          }
        });
        return () => {
          unsubscribe();
        };
      }
    }
  }, [listingData]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "30px",
        paddingTop: "100px",
        position: "absolute",
        top: "-10px",
        zIndex: 9999,
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    formGroup: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "0px",
    },
    checkBox: {
      "&.Mui-checked": {
        color: globalTheme.solidMainBlack.color,
      },
      color: globalTheme.solidMainBlack.color,
    },
    formControl: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      margin: "0px",
      width: "33%",
      "@media (max-width: 800px)": {
        width: "50%",
      },

      "& .MuiTypography-root": {
        fontSize: "11px !important",
        "@media (min-width: 350px)": {
          fontSize: "12px !important",
        },
        "@media (min-width: 550px)": {
          fontSize: "13px !important",
        },
        "@media (min-width: 700px)": {
          fontSize: "14px !important",
        },
      },
    },
    menuPaper: {
      "& .MuiPaper-root": {
        borderRadius: "8px",
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="ListingsPage"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}
    >
      {listingData !== null && (
        <Helmet prioritizeSeoTags>
          <title>GATH3R - {listingData.name}</title>
        </Helmet>
      )}
      <div
        className="ListingsPageTop"
        style={{
          backgroundColor: globalTheme.boxBackgroundWhite.color,
          boxShadow: globalTheme.boxShadow.boxShadow,
          border: `1px solid ${globalTheme.solidMainBlack.color}`,
          position: "relative",
        }}
      >
        <IconButton
          sx={{
            top: "3px",
            right: "3px",
            position: "absolute",
          }}
          size="small"
          color="inherit"
          onClick={handleMenuClick}
        >
          <MoreVertOutlinedIcon
            sx={{
              fontSize: "20px",
              color: globalTheme.textMainBlack.color,
              "@media (min-width: 350px)": {
                fontSize: "22px",
              },
              "@media (min-width: 550px)": {
                fontSize: "24px",
              },
              "@media (min-width: 700px)": {
                fontSize: "26px",
              },
            }}
          />
        </IconButton>
        <div className="ListingsPageTopInner">
          <div className="ListingsPageTopInnerAvatar">
            {listingData === null || isLoading ? (
              <Skeleton
                variant="circular"
                sx={{
                  width: "65px",
                  height: "65px",
                  borderRadius: "50%",
                  "@media (min-width: 350px)": {
                    width: "75px",
                    height: "75px",
                  },
                  "@media (min-width: 550px)": {
                    width: "85px",
                    height: "85px",
                  },
                  "@media (min-width: 700px)": {
                    width: "95px",
                    height: "95px",
                  },
                }}
              />
            ) : listingData.logo !== "" &&
              listingData.logo !== undefined &&
              listingData.logo !== null ? (
              <div className="ListingItemAvatar">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={listingData.logo}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: globalTheme.boxBackgroundWhite.color,
                  color: globalTheme.textMainBlack.color,
                  width: "65px",
                  height: "65px",
                  borderRadius: "50%",
                  fontSize: "16px",
                  "@media (min-width: 350px)": {
                    width: "75px",
                    height: "75px",
                    fontSize: "17px",
                  },
                  "@media (min-width: 550px)": {
                    width: "85px",
                    height: "85px",
                    fontSize: "18px",
                  },
                  "@media (min-width: 700px)": {
                    width: "95px",
                    height: "95px",
                    fontSize: "20px",
                  },
                }}
                alt={listingData.name}
              >
                {listingData.name.substr(0, 2)}
              </Avatar>
            )}
          </div>
          <div className="ListingsPageTopInnerText">
            {listingData === null || isLoading ? (
              <Skeleton
                variant="text"
                sx={{
                  width: "60%",
                  fontSize: "20px",
                  marginLeft: "15px",
                  "@media (min-width: 350px)": {
                    width: "80px",
                    fontSize: "22px",
                  },
                  "@media (min-width: 550px)": {
                    width: "100px",
                    fontSize: "24px",
                  },
                }}
              />
            ) : (
              <p
                className="ListingsPageHeaderTitle"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {listingData.name}{" "}
                {listingData.manualVerified && (
                  <Tooltip title="Verified by GATH3R">
                    <VerifiedUserOutlinedIcon
                      sx={{
                        fontSize: "16px",
                        marginBottom: "-1px",
                        color: globalTheme.figmaLinks.color,
                        marginLeft: "5px",
                        "@media (min-width: 350px)": {
                          fontSize: "18px",
                          marginBottom: "-2px",
                        },
                        "@media (min-width: 550px)": {
                          fontSize: "22px",
                        },
                        "@media (min-width: 700px)": {
                          fontSize: "25px",
                        },
                      }}
                    />
                  </Tooltip>
                )}
              </p>
            )}
            {listingData === null || isLoading ? (
              <Skeleton
                variant="text"
                sx={{
                  width: "50px",
                  fontSize: "12px",
                  marginLeft: "15px",
                  marginTop: "5px",
                  "@media (min-width: 350px)": {
                    width: "60px",
                    fontSize: "15px",
                  },
                  "@media (min-width: 550px)": {
                    width: "70px",
                    fontSize: "16px",
                  },
                }}
              />
            ) : (
              <p
                className="ListingsPageHeaderSub"
                style={{ color: globalTheme.figmaAlt1.color }}
              >
                {listingData.category}
              </p>
            )}
          </div>
        </div>
        {listingData !== null ? (
          <div className="ListingsPageHeaderRating">
            <RatingItem listItem={listingData} />
          </div>
        ) : (
          <Skeleton
            variant="text"
            sx={{
              width: "50%",
              fontSize: "14px",
              "@media (min-width: 350px)": {
                fontSize: "15px",
              },
              "@media (min-width: 550px)": {
                fontSize: "16px",
              },
            }}
          />
        )}
        {listingData === null || isLoading ? (
          <>
            <Skeleton
              variant="text"
              sx={{
                width: "92%",
                fontSize: "14px",
                "@media (min-width: 350px)": {
                  fontSize: "15px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "16px",
                },
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                width: "92%",
                fontSize: "14px",
                "@media (min-width: 350px)": {
                  fontSize: "15px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "16px",
                },
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                width: "92%",
                fontSize: "14px",
                "@media (min-width: 350px)": {
                  fontSize: "15px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "16px",
                },
              }}
            />
          </>
        ) : (
          <p
            className="ListingsPageHeaderDesc"
            style={{ color: globalTheme.textMainBlack.color }}
          >
            {listingData.description}
          </p>
        )}
        {listingData !== null &&
          listingData.website !== undefined &&
          listingData.website !== null &&
          listingData.website !== "" && (
            <a
              href={prependHTTP(listingData.website)}
              className="ListingsPageWebsiteLink"
              style={{ color: globalTheme.figmaAlt1.color }}
              target="_blank"
              rel="noopener noreferrer"
            >{`${removeUrlPrefix(listingData.website)
              .charAt(0)
              .toUpperCase()}${removeUrlPrefix(listingData.website).slice(
              1
            )}`}</a>
          )}
        <div className="ListingsPageDescSocial">
          {listingData !== null &&
            listingData.xHandle !== "" &&
            listingData.xHandle !== undefined &&
            listingData.xHandle !== null && (
              <a
                href={`https://x.com/${removeTwitterPrefix(
                  listingData.xHandle
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <XLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {listingData !== null &&
            listingData.facebookUrl !== "" &&
            listingData.facebookUrl !== undefined &&
            listingData.facebookUrl !== null && (
              <a
                href={listingData.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <FacebookLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {listingData !== null &&
            listingData.instagramHandle !== "" &&
            listingData.instagramHandle !== undefined &&
            listingData.instagramHandle !== null && (
              <a
                href={`https://instagram.com/${listingData.instagramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <InstagramLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {listingData !== null &&
            listingData.telegramHandle !== "" &&
            listingData.telegramHandle !== undefined &&
            listingData.telegramHandle !== null && (
              <a
                href={`https://t.me/${listingData.telegramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <TelegramLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {listingData !== null &&
            listingData.substackHandle !== "" &&
            listingData.substackHandle !== undefined &&
            listingData.substackHandle !== null && (
              <a
                href={`https://${removeTwitterPrefix(
                  listingData.substackHandle
                )}.substack.com`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <SubstackLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {listingData !== null &&
            listingData.mediumHandle !== "" &&
            listingData.mediumHandle !== undefined &&
            listingData.mediumHandle !== null && (
              <a
                href={`https://medium.com/@${removeTwitterPrefix(
                  listingData.mediumHandle
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <MediumLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          <div className="ListingsPageTopInnerIcons">
            {isFollowing ? (
              <BookmarkOutlinedIcon
                onClick={onFollowClick}
                sx={{
                  cursor: "pointer",
                  opacity: listingData !== null ? 1 : 0,
                  color: globalTheme.textMainGrey.color,
                  fontSize: "20px",
                  "@media (min-width: 350px)": {
                    fontSize: "22px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "24px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "26px",
                  },
                }}
              />
            ) : (
              <BookmarkBorderOutlinedIcon
                onClick={onFollowClick}
                sx={{
                  cursor: "pointer",
                  opacity: listingData !== null ? 1 : 0,
                  color: globalTheme.textMainGrey.color,
                  fontSize: "20px",
                  "@media (min-width: 350px)": {
                    fontSize: "22px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "24px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "26px",
                  },
                }}
              />
            )}
            <IconButton
              sx={{
                margin: 0,
                color: globalTheme.textMainGrey.color,
                opacity: listingData !== null ? 1 : 0,
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon
                sx={{
                  fontSize: "16px",
                  color: globalTheme.textMainGrey.color,
                  "@media (min-width: 350px)": { fontSize: "17px" },
                  "@media (min-width: 550px)": { fontSize: "18px" },
                  "@media (min-width: 701px)": { fontSize: "20px" },
                }}
              />
            </IconButton>
          </div>
        </div>
        <div
          className="ListingsPageTopInnerDivider"
          style={{ backgroundColor: globalTheme.textMainGrey.color }}
        ></div>
        <div
          className="ListingsPageTopFooter"
          style={{ height: isTopExpanded ? "unset" : "24px" }}
        >
          <div className="ListingsPageTopTagsOuter">
            <div className="ListingsPageTopTags">
              {listingData !== null &&
                listingData.tags !== null &&
                listingData.tags !== undefined &&
                Array.isArray(listingData.tags) &&
                listingData.tags.map(
                  (keyword: string) =>
                    keyword !== "" && (
                      <p
                        key={keyword}
                        className="ListingsPageTag"
                        style={{
                          color: globalTheme.textMainGrey.color,
                          border: `1px solid ${globalTheme.textMainGrey.color}`,
                          cursor: "pointer",
                        }}
                        onClick={() => onTagClick(keyword)}
                      >
                        {keyword}
                      </p>
                    )
                )}
            </div>

            {/*
            <div className="ListingsPageTopFooterButton">
              <IconButton
                aria-label="filter"
                sx={{
                  color: globalTheme.textMainBlack.color,
                  opacity: listingData !== null ? 1 : 0,
                  marginRight: "-5px",
                }}
                size="small"
                onClick={() => setIsTopExpanded(!isTopExpanded)}
              >
                {isTopExpanded ? (
                  <ExpandLessOutlinedIcon
                    sx={{
                      fontSize: "22px",
                      "@media (min-width: 350px)": { fontSize: "24px" },
                      "@media (min-width: 550px)": { fontSize: "26px" },
                      "@media (min-width: 701px)": { fontSize: "28px" },
                    }}
                  />
                ) : (
                  <ExpandMoreOutlinedIcon
                    sx={{
                      fontSize: "22px",
                      "@media (min-width: 350px)": { fontSize: "24px" },
                      "@media (min-width: 550px)": { fontSize: "26px" },
                      "@media (min-width: 701px)": { fontSize: "28px" },
                    }}
                  />
                )}
              </IconButton>
                  </div>*/}
          </div>
          <div className="ListingsPageTopBottom">
            <div className="ListingsPageTopAuthor">
              <p
                className="ListingsPageHeaderDesc"
                style={{
                  color: globalTheme.textMainBlack.color,
                  width: "unset",
                  marginBottom: "0px",
                  marginTop: "0px",
                }}
              >
                Submitted:{" "}
              </p>
              {listingData === null ? (
                <Skeleton
                  variant="text"
                  sx={{
                    width: "60px",
                    fontSize: "16px",
                    "@media (min-width: 350px)": {
                      fontSize: "17px",
                    },
                    "@media (min-width: 550px)": {
                      fontSize: "18px",
                    },
                  }}
                />
              ) : (
                <p
                  className="ListingsPageWebsiteLink"
                  style={{
                    color: globalTheme.textMainBlack.color,
                    marginBottom: "0px",
                    marginTop: "-1px",
                    width: "unset",
                  }}
                >
                  {moment(listingData.createdAt).format("MMM Do YYYY h:mm a")}
                </p>
              )}
            </div>
            {listingData !== null &&
              listingData.createdBy !== undefined &&
              listingData.createdBy !== null &&
              listingData.createdBy !== "" && (
                <div className="ListingsPageTopAuthor">
                  <p
                    className="ListingsPageHeaderDesc"
                    style={{
                      color: globalTheme.textMainBlack.color,
                      width: "unset",
                      marginBottom: "0px",
                      marginTop: "0px",
                    }}
                  >
                    By:{" "}
                  </p>
                  {authorName === "" ? (
                    <Skeleton
                      variant="text"
                      sx={{
                        width: "60px",
                        fontSize: "16px",
                        "@media (min-width: 350px)": {
                          fontSize: "17px",
                        },
                        "@media (min-width: 550px)": {
                          fontSize: "18px",
                        },
                      }}
                    />
                  ) : (
                    <p
                      className="ListingsPageWebsiteLink"
                      style={{
                        color: globalTheme.solidMainBlue.color,
                        marginBottom: "0px",
                        marginTop: "0px",
                        width: "unset",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(`/users/page/${listingData.createdBy}`)
                      }
                    >
                      {authorName}
                    </p>
                  )}
                </div>
              )}
          </div>
          {/*<div className="ListingsPageTopFooterInner">
            {listingData !== null &&
            listingData.viewsDay !== null &&
            listingData.viewsDay !== undefined &&
            listingData.viewsDay !== 0 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Daliy Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  {listingData.viewsDay}
                </p>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Daliy Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200
                </p>
              </div>
            )}
            {listingData !== null &&
            listingData.viewsMonth !== null &&
            listingData.viewsMonth !== undefined &&
            listingData.viewsMonth !== 0 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Monthly Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  {listingData.viewsMonth}
                </p>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Monthly Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200
                </p>
              </div>
            )}
            {listingData !== null &&
            listingData.viewsYear !== null &&
            listingData.viewsYear !== undefined &&
            listingData.viewsYear !== 0 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Yearly Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  {listingData.viewsYear}
                </p>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Yearly Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200
                </p>
              </div>
            )}
            {listingData !== null &&
            listingData.viewsTotal !== null &&
            listingData.viewsTotal !== undefined &&
            listingData.viewsTotal !== 0 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Total Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  {listingData.viewsTotal}
                </p>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Total Views
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200
                </p>
              </div>
            )}
          </div>*/}
          {/*<div className="ListingsPageTopFooterInner">
            {listingData !== null &&
            listingData.trendingHour !== null &&
            listingData.trendingHour !== undefined &&
            listingData.trendingHour !== 0 &&
            listingData.trendingHourUpdated !== null &&
            listingData.trendingHourUpdated !== undefined &&
            listingData.trendingHourUpdated >
              Date.now() - 60 * 60 * 1000 * 3 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Hour
                </p>
                <div className="ListingsPageTrending">
                  {listingData.trendingHour > 150 && (
                    <NorthEastIcon
                      sx={{
                        color: "#00c43e",
                        fontSize: "14px",
                        marginRight: "2px",
                      }}
                    />
                  )}
                  {listingData.trendingHour < 50 && (
                    <SouthEastIcon
                      sx={{
                        color: "#c40000",
                        fontSize: "14px",
                        marginRight: "2px",
                      }}
                    />
                  )}
                  {listingData.trendingHour >= 50 &&
                    listingData.trendingHour <= 150 && (
                      <EastIcon
                        color="inherit"
                        sx={{ fontSize: "14px", marginRight: "2px" }}
                      />
                    )}
                  <p
                    className="ListingsPageTopFooterSub"
                    style={{ color: globalTheme.figmaPrimaryText.color }}
                  >
                    {listingData.trendingHour < 1000 &&
                      listingData.trendingHour + "%"}
                    {listingData.trendingHour >= 1000 && "> 1000%"}
                  </p>
                </div>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Hour
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200%
                </p>
              </div>
            )}
            {listingData !== null &&
            listingData.trendingDay !== null &&
            listingData.trendingDay !== undefined &&
            listingData.trendingDay !== 0 &&
            listingData.trendingDayUpdated !== null &&
            listingData.trendingDayUpdated !== undefined &&
            listingData.trendingDayUpdated >
              Date.now() - 60 * 60 * 1000 * 48 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Day
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  <div className="ListingsPageTrending">
                    {listingData.trendingDay > 150 && (
                      <NorthEastIcon
                        sx={{
                          color: "#00c43e",
                          fontSize: "14px",
                          marginRight: "2px",
                        }}
                      />
                    )}
                    {listingData.trendingDay < 50 && (
                      <SouthEastIcon
                        sx={{
                          color: "#c40000",
                          fontSize: "14px",
                          marginRight: "2px",
                        }}
                      />
                    )}
                    {listingData.trendingDay >= 50 &&
                      listingData.trendingDay <= 150 && (
                        <EastIcon
                          color="inherit"
                          sx={{ fontSize: "14px", marginRight: "2px" }}
                        />
                      )}
                    <p
                      className="ListingsPageTopFooterSub"
                      style={{ color: globalTheme.figmaPrimaryText.color }}
                    >
                      {listingData.trendingDay < 1000 &&
                        listingData.trendingDay + "%"}
                      {listingData.trendingDay >= 1000 && "> 1000%"}
                    </p>
                  </div>
                </p>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Day
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200%
                </p>
              </div>
            )}
            {listingData !== null &&
            listingData.trendingWeek !== null &&
            listingData.trendingWeek !== undefined &&
            listingData.trendingWeek !== 0 &&
            listingData.trendingWeekUpdated !== null &&
            listingData.trendingWeekUpdated !== undefined &&
            listingData.trendingWeekUpdated >
              Date.now() - 60 * 60 * 1000 * 24 * 7 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Week
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  <div className="ListingsPageTrending">
                    {listingData.trendingWeek > 150 && (
                      <NorthEastIcon
                        sx={{
                          color: "#00c43e",
                          fontSize: "14px",
                          marginRight: "2px",
                        }}
                      />
                    )}
                    {listingData.trendingWeek < 50 && (
                      <SouthEastIcon
                        sx={{
                          color: "#c40000",
                          fontSize: "14px",
                          marginRight: "2px",
                        }}
                      />
                    )}
                    {listingData.trendingWeek >= 50 &&
                      listingData.trendingWeek <= 150 && (
                        <EastIcon
                          color="inherit"
                          sx={{ fontSize: "14px", marginRight: "2px" }}
                        />
                      )}
                    <p
                      className="ListingsPageTopFooterSub"
                      style={{ color: globalTheme.figmaPrimaryText.color }}
                    >
                      {listingData.trendingWeek < 1000 &&
                        listingData.trendingWeek + "%"}
                      {listingData.trendingWeek >= 1000 && "> 1000%"}
                    </p>
                  </div>
                </p>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Week
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200%
                </p>
              </div>
            )}
            {listingData !== null &&
            listingData.trendingMonth !== null &&
            listingData.trendingMonth !== undefined &&
            listingData.trendingMonth !== 0 &&
            listingData.trendingMonthUpdated !== null &&
            listingData.trendingMonthUpdated !== undefined &&
            listingData.trendingMonthUpdated >
              Date.now() - 60 * 60 * 1000 * 24 * 30 ? (
              <div className="ListingsPageTopFooterTextItem">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Month
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  <div className="ListingsPageTrending">
                    {listingData.trendingMonth > 150 && (
                      <NorthEastIcon
                        sx={{
                          color: "#00c43e",
                          fontSize: "14px",
                          marginRight: "2px",
                        }}
                      />
                    )}
                    {listingData.trendingMonth < 50 && (
                      <SouthEastIcon
                        sx={{
                          color: "#c40000",
                          fontSize: "14px",
                          marginRight: "2px",
                        }}
                      />
                    )}
                    {listingData.trendingMonth >= 50 &&
                      listingData.trendingMonth <= 150 && (
                        <EastIcon
                          color="inherit"
                          sx={{ fontSize: "14px", marginRight: "2px" }}
                        />
                      )}
                    <p
                      className="ListingsPageTopFooterSub"
                      style={{ color: globalTheme.figmaPrimaryText.color }}
                    >
                      {listingData.trendingMonth < 1000 &&
                        listingData.trendingMonth + "%"}
                      {listingData.trendingMonth >= 1000 && "> 1000%"}
                    </p>
                  </div>
                </p>
              </div>
            ) : (
              <div className="ListingsPageTopFooterTextItemBlur">
                <p
                  className="ListingsPageTopFooterTitle"
                  style={{ color: globalTheme.figmaCoreOrange.color }}
                >
                  Trending Month
                </p>
                <p
                  className="ListingsPageTopFooterSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  200%
                </p>
              </div>
            )}
          </div>*/}
        </div>
      </div>

      {listingData !== null && !isLoading && (
        <div className="ListingsPageTabsOuter">
          <div className="ListingsPageTabs">
            <ButtonBase
              onClick={() => setActiveTab("reviews")}
              sx={{ width: "30%" }}
            >
              <div
                className="ListingsPageTabsInner"
                style={{
                  color:
                    activeTab === "reviews"
                      ? globalTheme.backgroundMainBeige.color
                      : globalTheme.backgroundMainOffBeige.color,
                  backgroundColor:
                    activeTab === "reviews"
                      ? globalTheme.backgroundMainOffBeige.color
                      : "transparent",
                }}
              >
                <RateReviewOutlinedIcon
                  color="inherit"
                  sx={{
                    fontSize: "16px",
                    marginRight: "5px",
                    marginTop: "2px",
                    "@media (min-width: 350px)": {
                      fontSize: "17px",
                    },
                    "@media (min-width: 550px)": {
                      fontSize: "18px",
                    },
                    "@media (min-width: 700px)": {
                      fontSize: "19px",
                    },
                  }}
                />
                <p className="ListingsPageTabsText">Reviews</p>
              </div>
            </ButtonBase>
            <ButtonBase
              onClick={() => setActiveTab("news")}
              sx={{ width: "30%" }}
            >
              <div
                className="ListingsPageTabsInner"
                style={{
                  color:
                    activeTab === "news"
                      ? globalTheme.backgroundMainBeige.color
                      : globalTheme.backgroundMainOffBeige.color,
                  backgroundColor:
                    activeTab === "news"
                      ? globalTheme.backgroundMainOffBeige.color
                      : "transparent",
                }}
              >
                <NewspaperOutlinedIcon
                  color="inherit"
                  sx={{
                    fontSize: "16px",
                    marginRight: "5px",
                    "@media (min-width: 350px)": {
                      fontSize: "17px",
                    },
                    "@media (min-width: 550px)": {
                      fontSize: "18px",
                    },
                    "@media (min-width: 700px)": {
                      fontSize: "19px",
                    },
                  }}
                />
                <p className="ListingsPageTabsText">News</p>
              </div>
            </ButtonBase>
            <ButtonBase
              onClick={() => setActiveTab("more")}
              sx={{ width: "30%" }}
            >
              <div
                className="ListingsPageTabsInner"
                style={{
                  color:
                    activeTab === "more"
                      ? globalTheme.backgroundMainBeige.color
                      : globalTheme.backgroundMainOffBeige.color,
                  backgroundColor:
                    activeTab === "more"
                      ? globalTheme.backgroundMainOffBeige.color
                      : "transparent",
                }}
              >
                <MoreHorizOutlinedIcon
                  color="inherit"
                  sx={{
                    fontSize: "16px",
                    marginRight: "5px",
                    "@media (min-width: 350px)": {
                      fontSize: "17px",
                    },
                    "@media (min-width: 550px)": {
                      fontSize: "18px",
                    },
                    "@media (min-width: 700px)": {
                      fontSize: "19px",
                    },
                  }}
                />
                <p className="ListingsPageTabsText">Other</p>
              </div>
            </ButtonBase>
          </div>
          {activeTab === "reviews" ? (
            <ListingPageReviewPreviews listing={listingData} />
          ) : activeTab === "news" ? (
            <NewsOuter listing={listingData} />
          ) : (
            <ListingPageInfo listing={listingData} />
          )}
        </div>
      )}
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        sx={styles.menuPaper}
      >
        <MenuItem
          dense
          onClick={() => {
            setListingReportDialog(true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <ReportOutlined
              fontSize="small"
              sx={{ color: globalTheme.backgroundMainOffBeige.color }}
            />
          </ListItemIcon>
          <ListItemText>
            Report{" "}
            {listingData?.name === "" ? "this listing" : listingData?.name}
          </ListItemText>
        </MenuItem>
        {authStates.role === "admin" || authStates.role === "owner" ? (
          <Divider />
        ) : null}
        {authStates.role === "admin" || authStates.role === "owner" ? (
          <MenuItem
            dense
            onClick={() => {
              setAdminEditDialog(true);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <SettingsOutlined
                fontSize="small"
                sx={{ color: globalTheme.backgroundMainOffBeige.color }}
              />
            </ListItemIcon>
            <ListItemText>Admin Settings</ListItemText>
          </MenuItem>
        ) : null}
      </Menu>
      <Dialog
        open={listingReportDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onListingReportCancel}
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          },
        }}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={onListingReportCancel}
        >
          <CloseOutlined />
        </IconButton>
        <DialogTitle
          sx={{
            ...standardDialogTitle,
            width: "calc(100% - 8px)",
            marginLeft: "4px",
            lineHeight: "110%",
          }}
        >
          Report {listingData?.name === "" ? "this listing" : listingData?.name}
          <br />
          <span style={{ fontSize: "55%", fontWeight: 400 }}>
            Please select your reasons for reporting this entry.
          </span>
        </DialogTitle>
        <DialogContent>
          <FormGroup sx={styles.formGroup} row>
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"duplicate"}
                  checked={reasonArray.includes("duplicate")}
                  onChange={handleReasonChange}
                />
              }
              label="Duplicate"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"spam"}
                  checked={reasonArray.includes("spam")}
                  onChange={handleReasonChange}
                />
              }
              label="Spam"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"content"}
                  checked={reasonArray.includes("content")}
                  onChange={handleReasonChange}
                />
              }
              label="Inappropriate content"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"harassment"}
                  checked={reasonArray.includes("harassment")}
                  onChange={handleReasonChange}
                />
              }
              label="Harassment"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"imperonate"}
                  checked={reasonArray.includes("imperonate")}
                  onChange={handleReasonChange}
                />
              }
              label="Impersonation"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"falseInfo"}
                  checked={reasonArray.includes("falseInfo")}
                  onChange={handleReasonChange}
                />
              }
              label="False information"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"hate"}
                  checked={reasonArray.includes("hate")}
                  onChange={handleReasonChange}
                />
              }
              label="Hate speech"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"violence"}
                  checked={reasonArray.includes("violence")}
                  onChange={handleReasonChange}
                />
              }
              label="Violence"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"rugged"}
                  checked={reasonArray.includes("rugged")}
                  onChange={handleReasonChange}
                />
              }
              label="Rugged"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"other"}
                  checked={reasonArray.includes("other")}
                  onChange={handleReasonChange}
                />
              }
              label="Other"
            />
          </FormGroup>
          <div className="AdminListDialogEditButtons">
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
                flex: "unset",
                minWidth: "90px",
              }}
              onClick={onListingReportCancel}
              disabled={isSendingListingReport}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                flex: "unset",
                marginLeft: "20px",
                minWidth: "90px",
              }}
              onClick={onListingReportSubmit}
              disabled={isSendingListingReport || reasonString === ""}
            >
              {isSendingListingReport ? "Submitting..." : "Submit"}
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
      {authStates.role === "admin" || authStates.role === "owner" ? (
        <Dialog
          open={adminEditDialog}
          TransitionComponent={Transition}
          onClose={() => setAdminEditDialog(false)}
          sx={{
            ...standardDialogRoot,
            "& .MuiDialog-paper": {
              ...standardDialogPaper,
              backgroundColor: globalTheme.backgroundMainBeige.color,
              color: globalTheme.backgroundMainOffBeige.color,
              border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            },
          }}
        >
          <IconButton
            sx={{
              color: globalTheme.textMainGrey.color,
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "25px",
              right: "15px",
            }}
            size="small"
            onClick={() => setAdminEditDialog(false)}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent
            sx={{
              marginTop: "0px",
              paddingTop: "0px",
              paddingBottom: "50px",
            }}
          >
            <AdminListingDialog
              lastUpdatedAt={0}
              listingId={listingId}
              listingName={listingData?.name}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}
