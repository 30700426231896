export const figmaColorVariables = {
  black: '#0D0D0D',
  white: '#FFFFFF',
  yellow100: '#FFFBE5',
  yellow200: '#FFF4BA',
  yellow300: '#FFED8F',
  yellow400: '#FFE348',
  yellow500: '#F7D100',
  orange100: '#FDFBF7',
  orange200: '#F8F4EA',
  orange300: '#FFD379',
  orange400: '#FAB933',
  orange500: '#F9AB0B',
  red100: '#FFE7E7',
  red200: '#EE8F8F',
  red300: '#E37272',
  red400: '#C53939',
  red500: '#9D1C1C',
  green100: '#E0F8F7',
  green200: '#B8EAE9',
  green300: '#8FE7E4',
  green400: '#34CFC8',
  green500: '#03B0A9',
  blue100: '#E5F2F8',
  blue200: '#B5DFF1',
  blue300: '#82CDEC',
  blue400: '#48A8D1',
  blue500: '#267698',
  grey100: '#FAFAFA',
  grey200: '#EDEDED',
  grey300: '#A0A0A0',
  grey400: '#6A6A6A',
  grey500: '#5A5A5A',
  grey600: '#4A4A4A',
  grey700: '#3A3A3A',
  grey800: '#2A2A2A',
  grey900: '#1A1A1A',
  alt1: '#624E4E'
};

export const globalThemeLight = {
  background: {
    backgroundColor: "#FDF7EA",
  },
  altBackground: {
    backgroundColor: "#FFD379",
  },
  paperBackground: {
    backgroundColor: "#fffbf3",
  },
  altPaperBackground: {
    backgroundColor: "#FFD379",
  },
  buttonBackground: {
    backgroundColor: "#fffbf3",
  },
  altButtonBackground: {
    backgroundColor: "#FFD379",
  },
  textColor: {
    color: "#39393C",
  },
  altTextColor: {
    color: "#B6AEA0",
  },
  altTextColorDarker: {
    color: "#8a857b",
  },
  iconColor: {
    color: "#828282",
  },
  altIconColor: {
    color: "#FFD379",
  },
  boxShadow: {
    boxShadow: `0px 3px 1px #39393C`,
  },
  figmaPrimaryColor: {
    color: "#FFD379",
  },
  figmaBeigeBG: {
    color: "#FDF7EA",
  },
  figmaTxt: {
    color: "#39393C",
  },
  figmaPrimaryText: {
    color: "#39393C",
  },
  figmaSecondaryText: {
    color: "#B6AEA0",
  },
  figmaPrimaryTextSub: {
    color: "#696969",
  },
  figmaLinkTextSub: {
    color: "#ACACAC",
  },
  figmaHighlight: {
    color: "#60A6E7",
  },
  figmaLightBG: {
    color: "#FFFBF3",
  },
  figmaVerified: {
    color: "#5BBA70",
  },
  figmaGOrange: {
    color: "#F8A748",
  },
  figmaGOrangeDark: {
    color: "#E9AA29",
  },
  figmaLinkEmphasis: {
    color: "#47CCF6",
  },
  figmaDashboardIcon: {
    color: "#828282",
  },

  figmaBeigeLight: {
    color: "#FFFBF3",
  },
  figmaBeigeDark: {
    color: "#F5F2EB",
  },
  figmaLinks: {
    color: "#47CCF6",
  },
  figmaCreamLight: {
    color: "#F6ECC9",
  },
  figmaCreamDark: {
    color: "#C1B073",
  },
  figmaAlt1: {
    color: "#729994",
  },
  figmaAlt2: {
    color: "#A6E7CF",
  },
  figmaCoreOrange: {
    color: "#FFD379",
  },
  figmaCoreOrangeDark: {
    color: "#E9AA29",
  },
  figmaWhite: {
    color: "#ffffff",
  },
  figmaExploreSearch: {
    color: "#D9D9D9",
  },
  figmaYellowPastel: {
    color: "#FAE472",
  },
  figmaYellowAlt: {
    color: "#FFF5C1",
  },
  figmaGreenPastel: {
    color: "#AAE1A9",
  },

  figmaBluePastel: {
    color: "#B1E2FE",
  },
  figmaPurplePastel: {
    color: "#CBA5F0",
  },
  figmaGreyPastel: {
    color: "#CFCFCF",
  },
  figmaWarningBackground: {
    color: "#fa9272",
  },

  // New colors

  // Backgrounds & Off colors
  backgroundMainYellow: {
    color: "#FAEC72",
  },
  backgroundMainOffYellow: {
    color: "#39393C",
  },
  backgroundMainBeige: {
    color: "#FFFBF3",
  },
  backgroundMainOffBeige: {
    color: "#313131",
  },
  backgroundMainBeigeDark: {
    color: "#F5F2EB",
  },
  backgroundMainOffBeigeDark: {
    color: "#3A3A3A",
  },

  // Solids
  solidMainYellow: {
    color: "#FAEC72",
  },
  solidMainBlack: {
    color: "#39393C",
  },
  solidMainWhite: {
    color: "#FFFFFF",
  },
  solidMainBeige: {
    color: "#FFFBF3",
  },
  solidMainBeigeDark: {
    color: "#F5F2EB",
  },
  solidMainGreen: {
    color: "#A6E7CF",
  },
  solidMainBlue: {
    color: "#47CCF6",
  },

  // Boxes
  boxBackgroundWhite: {
    color: "#FFFFFF",
  },
  boxBorderColor: {
    color: "#FFFFFF",
  },
  boxBackgroundYellow: {
    color: "#FAEC72",
  },
  boxBackgroundOffYellow: {
    color: "#3A3A3A",
  },
  loginBoxBorder: {
    color: "#39393C",
  },

  // Text colors
  textMainBlack: {
    color: "#39393C",
  },
  textMainOffBlack: {
    color: "#FFFFFF",
  },
  textMainGrey: {
    color: "#696969",
  },

  // Misc
  coreOrange: {
    color: "#FFD379",
  },
  coreOrangeOff: {
    color: "#39393C",
  },
  coreOrangeAnti: {
    color: "#FFD379",
  },
  coreOrangeOffAnti: {
    color: "#39393C",
  },
  pickerOrangeYellow: {
    color: "#FFD379",
  },
  pickerGreenYellow: {
    color: "#A6E7CF",
  },
  pickerGreenTransparent: {
    color: "#A6E7CF",
  },
  tabSelected: {
    color: "#FFD379",
  },

  // Color Palette
  colorPaletteBlue: {
    color: "#9AC2DA",
  },
  colorPaletteGreen: {
    color: "#C8DF94",
  },
  colorPalettepink: {
    color: "#FABDA5",
  },
  colorPalettePurple: {
    color: "#C0B1D6",
  },
  colorPaletteBeige: {
    color: "#FFF3E2",
  },
  colorPaletteRed: {
    color: "#FF9781",
  },
  colorPaletteYellow: {
    color: "#FFF8BE",
  },
  colorPaletteButtonRed: {
    color: "#FFE7E7",
  },
  colorPaletteButtonGreen: {
    color: "#8FE7E4",
  },

  // Dashboard
  dashboardBackground: {
    color: "#FFED8F",
  },

  // Explore
  exploreBackground: {
    color: "#8FE7E4",
  },
  exploreBackgroundOff: {
    color: "#313131",
  },

  //New colors

  // Surfaces
  primarySurface: {
    color: figmaColorVariables.orange100,
  },

  //Text 
  primaryText: {
    color: figmaColorVariables.grey900,
  },

  ruggedSurface: {
    color: figmaColorVariables.grey200,
  },

  primaryBoxshadow: {
    boxShadow: "0px 2px 4px 0px rgba(74, 74, 74, 0.05)",
  },

  iconNeutral: {
    color: figmaColorVariables.grey300,
  },

  links: {
    color: figmaColorVariables.green400,
  },

};

export const globalThemeDark = {
  background: {
    backgroundColor: "#FDF7EA",
  },
  altBackground: {
    backgroundColor: "#FFD379",
  },
  paperBackground: {
    backgroundColor: "#fffbf3",
  },
  altPaperBackground: {
    backgroundColor: "#FFD379",
  },
  buttonBackground: {
    backgroundColor: "#fffbf3",
  },
  altButtonBackground: {
    backgroundColor: "#FFD379",
  },
  textColor: {
    color: "#39393C",
  },
  altTextColor: {
    color: "#B6AEA0",
  },
  altTextColorDarker: {
    color: "#8a857b",
  },
  iconColor: {
    color: "#828282",
  },
  altIconColor: {
    color: "#FFD379",
  },
  boxShadow: {
    boxShadow: `0px 3px 1px #39393C`,
  },
  figmaPrimaryColor: {
    color: "#FFD379",
  },
  figmaBeigeBG: {
    color: "#FDF7EA",
  },
  figmaTxt: {
    color: "#39393C",
  },
  figmaPrimaryText: {
    color: "#39393C",
  },
  figmaSecondaryText: {
    color: "#B6AEA0",
  },
  figmaPrimaryTextSub: {
    color: "#696969",
  },
  figmaLinkTextSub: {
    color: "#ACACAC",
  },
  figmaHighlight: {
    color: "#60A6E7",
  },
  figmaLightBG: {
    color: "#FFFBF3",
  },
  figmaVerified: {
    color: "#5BBA70",
  },
  figmaGOrange: {
    color: "#F8A748",
  },
  figmaGOrangeDark: {
    color: "#E9AA29",
  },
  figmaLinkEmphasis: {
    color: "#47CCF6",
  },
  figmaDashboardIcon: {
    color: "#828282",
  },

  figmaBeigeLight: {
    color: "#FFFBF3",
  },
  figmaBeigeDark: {
    color: "#F5F2EB",
  },
  figmaLinks: {
    color: "#47CCF6",
  },
  figmaCreamLight: {
    color: "#F6ECC9",
  },
  figmaCreamDark: {
    color: "#C1B073",
  },
  figmaAlt1: {
    color: "#729994",
  },
  figmaAlt2: {
    color: "#A6E7CF",
  },
  figmaCoreOrange: {
    color: "#FFD379",
  },
  figmaCoreOrangeDark: {
    color: "#E9AA29",
  },
  figmaWhite: {
    color: "#ffffff",
  },
  figmaExploreSearch: {
    color: "#D9D9D9",
  },
  figmaYellowPastel: {
    color: "#FAE472",
  },
  figmaYellowAlt: {
    color: "#FFF5C1",
  },
  figmaGreenPastel: {
    color: "#AAE1A9",
  },

  figmaBluePastel: {
    color: "#B1E2FE",
  },
  figmaPurplePastel: {
    color: "#CBA5F0",
  },
  figmaGreyPastel: {
    color: "#CFCFCF",
  },
  figmaWarningBackground: {
    color: "#fa9272",
  },

  // New colors

  // Backgrounds & Off colors
  backgroundMainYellow: {
    color: "#313131",
  },
  backgroundMainOffYellow: {
    color: "#FAEC72",
  },
  backgroundMainBeige: {
    color: "#313131",
  },
  backgroundMainOffBeige: {
    color: "#FFFBF3",
  },
  backgroundMainBeigeDark: {
    color: "#3A3A3A",
  },
  backgroundMainOffBeigeDark: {
    color: "#F5F2EB",
  },

  // Solids
  solidMainYellow: {
    color: "#FAEC72",
  },
  solidMainBlack: {
    color: "#39393C",
  },
  solidMainWhite: {
    color: "#FFFFFF",
  },
  solidMainBeige: {
    color: "#FFFBF3",
  },
  solidMainBeigeDark: {
    color: "#F5F2EB",
  },
  solidMainGreen: {
    color: "#A6E7CF",
  },
  solidMainBlue: {
    color: "#47CCF6",
  },

  // Boxes
  boxBackgroundWhite: {
    color: "#3A3A3A",
  },
  boxBorderColor: {
    color: "#39393C",
  },
  boxBackgroundYellow: {
    color: "#3A3A3A",
  },
  boxBackgroundOffYellow: {
    color: "#FAEC72",
  },
  loginBoxBorder: {
    color: "#39393C",
  },

  // Text colors
  textMainBlack: {
    color: "#FFFFFF",
  },
  textMainOffBlack: {
    color: "#39393C",
  },
  textMainGrey: {
    color: "#ACACAC",
  },

  // Misc
  coreOrange: {
    color: "#FFD379",
  },
  coreOrangeOff: {
    color: "#39393C",
  },
  coreOrangeAnti: {
    color: "#39393C",
  },
  coreOrangeOffAnti: {
    color: "#FFD379",
  },
  pickerOrangeYellow: {
    color: "#FAEC72",
  },
  pickerGreenYellow: {
    color: "#FAEC72",
  },
  pickerGreenTransparent: {
    color: "transparent",
  },
  tabSelected: {
    color: "#FAEC72",
  },

  // Color Palette
  colorPaletteBlue: {
    color: "#9AC2DA",
  },
  colorPaletteGreen: {
    color: "#C8DF94",
  },
  colorPalettepink: {
    color: "#FABDA5",
  },
  colorPalettePurple: {
    color: "#C0B1D6",
  },
  colorPaletteBeige: {
    color: "#FFF3E2",
  },
  colorPaletteRed: {
    color: "#FF9781",
  },
  colorPaletteYellow: {
    color: "#FFF8BE",
  },
  colorPaletteButtonRed: {
    color: "#FFE7E7",
  },
  colorPaletteButtonGreen: {
    color: "#8FE7E4",
  },

  // Dashboard
  dashboardBackground: {
    color: "#313131",
  },


  // Explore
  exploreBackground: {
    color: "#313131",
  },
  exploreBackgroundOff: {
    color: "#8FE7E4",
  },


  //New colors

  // Surfaces
  primarySurface: {
    color: figmaColorVariables.grey800,
  },

  ruggedSurface: {
    color: figmaColorVariables.alt1,
  },

  //Text 
  primaryText: {
    color: figmaColorVariables.grey200,
  },

  primaryBoxshadow: {
    boxShadow: "0px 2px 4px 0px rgba(74, 74, 74, 0.05)",
  },

  iconNeutral: {
    color: figmaColorVariables.grey300,
  },

  links: {
    color: figmaColorVariables.green300,
  },
};