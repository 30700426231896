import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Rating from "@mui/material/Rating";
import { useThemeState } from "../../../components/utils/globalStates";

import moment from "moment";
import "./ListingsComponents.css";

moment().format();

export default function RatingItem({ listItem }: { listItem: any }) {
  const [reviewCountLoading, setReviewCountLoading] = useState(false);
  const [ratingCountLoading, setRatingCountLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Get review count
  useEffect(() => {
    setReviewCountLoading(true);
    if (
      listItem.reviewsCount === null ||
      listItem.reviewsCount === undefined ||
      listItem.reviewsCount === ""
    ) {
      setReviewCount(0);
      setReviewCountLoading(false);
    } else {
      setReviewCount(listItem.reviewsCount);
      setReviewCountLoading(false);
    }
  }, [listItem.reviewsCount]);

  // Get rating count
  useEffect(() => {
    setRatingCountLoading(true);
    if (
      listItem.ratingCount === null ||
      listItem.ratingCount === undefined ||
      listItem.ratingCount === ""
    ) {
      setRatingCount(0);
      setRatingCountLoading(false);
    } else {
      setRatingCount(listItem.ratingCount);
      setRatingCountLoading(false);
    }
  }, [listItem.ratingCount]);

  // Get rating
  useEffect(() => {
    setRatingLoading(true);
    if (
      listItem.rating === null ||
      listItem.rating === undefined ||
      listItem.rating === ""
    ) {
      setRating(0);
      setRatingLoading(false);
    } else {
      setRating(listItem.rating);
      setRatingLoading(false);
    }
  }, [listItem.rating]);

  return (
    <div className="RatingItem">
      {ratingLoading === true ? (
        <Skeleton
          variant="text"
          sx={{ width: "30%", fontSize: "11px", marginBottom: "0px" }}
        />
      ) : rating > 0 ? (
        <div className="RatingItemRating">
          <p
            className="RatingItemRatingText"
            style={{ color: globalTheme.textMainGrey.color }}
          >
            {rating.toFixed(1)}
          </p>
          <Rating value={rating} readOnly size="small" />
        </div>
      ) : (
        <div className="RatingItemRating">
          <Link
            to={`/listings/page/${listItem.id}`}
            className="RatingItemRatingText"
            style={{ color: globalTheme.textMainGrey.color }}
          >
            Be the first to rate or review
          </Link>
        </div>
      )}
      {ratingCountLoading === true ? (
        <Skeleton
          variant="text"
          sx={{
            width: "30%",
            fontSize: "11px",
            marginBottom: "0px",
            marginLeft: "3px",
          }}
        />
      ) : (
        ratingCount > 0 && (
          <p
            className="RatingItemRatingText"
            style={{
              color: globalTheme.textMainGrey.color,
              marginLeft: "3px",
            }}
          >
            {ratingCount} Ratings
          </p>
        )
      )}
      {reviewCountLoading === true ? (
        <Skeleton
          variant="text"
          sx={{
            width: "30%",
            fontSize: "11px",
            marginBottom: "0px",
            marginLeft: "3px",
          }}
        />
      ) : (
        reviewCount > 0 && (
          <p
            className="RatingItemRatingText"
            style={{
              color: globalTheme.textMainGrey.color,
              marginLeft: "3px",
            }}
          >
            {reviewCount} Reviews
          </p>
        )
      )}
    </div>
  );
}
