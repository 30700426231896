import React, { useEffect, useState } from "react";

import {
  query,
  deleteDoc,
  limit,
  collection,
  onSnapshot,
  doc,
  where,
} from "firebase/firestore";

import { CircularProgress, IconButton, ButtonBase, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";

import { Link } from "react-router-dom";
import aa from "search-insights";
import DOMPurify from "dompurify";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import CloseOutlined from "@mui/icons-material/CloseOutlined";

import { firestoreDb } from "../../components/firebase/firestore";
import { Waypoint } from "react-waypoint";
import {
  useThemeState,
  useAdminState,
} from "../../components/utils/globalStates";
import { figmaColorVariables } from "../../components/theme/globalTheme";
import {
  standardDialogPaper,
  standardDialogRoot,
} from "../../components/theme/globalMuiTheme";
import AdminListingDialog from "./dialogs/AdminListingDialog";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import { algoliaListingsRelevance } from "../../components/search/algoliaSearch";
import "./Admin.css";

const count = 12;

export default function AdminListingsFeatured() {
  const [user] = useAuthState(firebaseAuth);
  const [listings, setListings] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(count);
  const [listingDialogOpen, setListingDialogOpen] = useState(false);
  const [lastCount, setLastCount] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [querySearch, setQuerySearch] = useState("");
  const [results, setResults] = useState<any>([]);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const adminState = useAdminState((state) => state);

  useEffect(() => {
    if (adminState.activeListing !== "") {
      setListingDialogOpen(true);
    } else {
      setListingDialogOpen(false);
    }
  }, [adminState.activeListing]);

  const handleEndReached = () => {
    if (lastVisible !== lastCount) {
      return;
    }
    if (lastVisible === lastCount && listings.length < lastVisible) {
      return;
    }
    if (isFetchingMore === true) {
      return;
    }
    if (isLoading === true) {
      return;
    }
    setIsFetchingMore(true);
    setLastVisible(lastVisible + count);
  };

  useEffect(() => {
    let q = query(
      collection(firestoreDb, "listings"),
      where("featured", "==", true),
      limit(lastVisible)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (lastVisible === count) {
        setIsLoading(true);
      }
      setIsFetchingMore(true);
      let tempNews: any = [];
      querySnapshot.forEach((doc) => {
        let tempObj = doc.data();
        tempNews.push({ ...tempObj, id: doc.id });
      });
      setListings(tempNews);
      setLastCount(lastCount + count);
      setIsFetchingMore(false);
      setIsLoading(false);
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, [lastVisible]);

  const onResultClick = async (
    id: string,
    index: number,
    searchId: string,
    name: string
  ) => {
    adminState.setActiveListing(id);
    adminState.setActiveListingName(name);
    try {
      await aa("clickedObjectIDsAfterSearch", {
        index: "gath3r_listings",
        eventName: "Listing Clicked after Search",
        queryID: searchId,
        objectIDs: [id],
        positions: [index + 1],
      });
    } catch (error) {
      console.error(error);
      console.log(error);
    }
  };

  // Replace <em> tags with <strong> tags
  const replaceEm = (str: string) => {
    return str.replace(/<em>/g, "<strong>").replace(/<\/em>/g, "</strong>");
  };

  useEffect(() => {
    let tempArray: any[] = [];
    if (querySearch !== "") {
      setSearchLoading(true);
      algoliaListingsRelevance
        // @ts-ignore
        .search(querySearch, { clickAnalytics: true, userToken: user?.uid })
        .then((res: any) => {
          for (let i = 0; i < res.hits.length; i++) {
            const tempListing = res.hits[i];
            tempArray.push({
              data: tempListing,
              searchId: res.queryID,
            });
          }
          setResults(tempArray);
          setSearchLoading(false);
        })
        .catch((err: any) => {
          console.error(err);
          setSearchLoading(false);
        });
    }
  }, [querySearch]);

  const styles = {
    autoComplete: {
      transition: "transform 0.3s ease",
      marginBottom: "15px",
      marginTop: "20px",
      width: "calc(90% + 2px)",
      "& .MuiAutocomplete-popper": {},
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "8px",
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        backgroundColor: globalTheme.solidMainWhite.color,
        "& fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "& input": {
          "&::placeholder": {
            opacity: 1,
          },
        },
        "&:hover fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          color: globalTheme.figmaPrimaryText.color,
        },
      },
      "& label.Mui-focused": {
        color: globalTheme.figmaPrimaryText.color,
      },
      "& label": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
  };

  return (
    <div className="AdminInner">
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige, width: "90%" }}
      >
        STILL IN PROGRESS
      </p>
      <p
        className="AdminDescription"
        style={{
          ...globalTheme.backgroundMainOffBeige,
          width: "90%",
          maxWidth: "1200px",
        }}
      >
        List with all Featured projects. Click on a project to update or remove
        the featured highlight.
      </p>
      <div className="AdminExampleImagesOuter">
        <img
          style={{ width: "70%", height: "auto", marginBottom: "30px" }}
          src="https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fadmin%2Fadmin-example3.png?alt=media"
          alt="featured news example"
        />
      </div>

      <Autocomplete
                freeSolo
                fullWidth
                disableClearable
                sx={{
                  ...styles.autoComplete,
                }}
                size="small"
                filterOptions={(x) => x}
                loading={searchLoading}
                slotProps={{ popper: { sx: { zIndex: 99999 } } }}
                getOptionLabel={(option: any) => option.data.name}
                renderOption={(props: any, option: any) => {
                  return (
                    <ButtonBase
                      key={option.data.id}
                      onClick={() => {
                        onResultClick(
                          option.data.id,
                          props["data-option-index"],
                          option.searchId,
                          option.data.name
                        );
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "10px",
                        backgroundColor: "#fff",
                        zIndex: 10000,
                      }}
                    >
                      <p
                        className="AutocompleteResultTitle"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            replaceEm(option.data._highlightResult.name.value),
                            {
                              FORBID_ATTR: ["style"],
                              FORBID_TAGS: ["style", "a"],
                            }
                          ),
                        }}
                      ></p>
                      <p
                        className="AutocompleteResultText"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            replaceEm(
                              "in " +
                                option.data._highlightResult.category.value
                            ),
                            {
                              FORBID_ATTR: ["style"],
                              FORBID_TAGS: ["style", "a"],
                            }
                          ),
                        }}
                      ></p>
                    </ButtonBase>
                  );
                }}
                options={results}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={querySearch}
                    onChange={(event) => {
                      setQuerySearch(event.target.value);
                    }}
                    placeholder={'Add new Featured Project'}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <React.Fragment>
                          <SearchIcon
                            color="inherit"
                            sx={{ fontSize: "20px", marginLeft: "5px" }}
                          />
                        </React.Fragment>
                      ),
                      endAdornment: (
                        <React.Fragment>
                          {searchLoading ? (
                            <CircularProgress color="inherit" size={"20px"} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

      {isLoading ? (
        <div
          className="AdminListingsLoading"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      ) : listings.length > 0 ? (
        <div
          className="AdminListingsOuter"
          style={{ width: "90%", alignItems: "stretch" }}
        >
          {listings.map((article: any, index: number) => {
            return (
              <div className="AdminNewsItemOuter" key={index}>
                <div
                  className="AdminArticlePreviewLarge"
                  style={{
                    border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
                    color: globalTheme.primaryText.color,
                    backgroundColor: globalTheme.primarySurface.color,
                    width: "100%",
                    height: "calc(100% - 40px)",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    adminState.setActiveListing(article.id);
                    adminState.setActiveListingName(article.name);
                    try {
                      await aa("clickedObjectIDs", {
                        index: "gath3r_listings",
                        eventName: "Listing Clicked",
                        objectIDs: [article.id],
                      });
                    } catch (error) {
                      console.error(error);
                      console.log(error);
                    }
                  }}
                >
                  <div
                    className="AdminArticlePreviewLargeImgOuter"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img
                      src={article.featuredImage}
                      alt="Listing"
                      className="AdminArticlePreviewLargeImg"
                    />
                  </div>
                  <div className="AdminArticlePreviewLargeTextOuter">
                    <p className="AdminArticlePreviewLargeTitle">
                      {article.name}
                    </p>
                    <p className="AdminArticlePreviewLargeSub">
                      {article.featuredTitle}
                    </p>
                    <b
                      className="AdminArticlePreviewLargeText"
                      style={{ marginTop: "5px", overflow: "visible" }}
                    >
                      {article.featuredDescription}
                    </b>
                    <p className="AdminArticlePreviewLargeText">
                      {article.description}
                    </p>
                    <Link
                      to={`/listings/page/${article.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="AdminArticlePreviewLargeText"
                      style={{
                        color: figmaColorVariables.blue300,
                        marginTop: "0px",
                        marginBottom: "5px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Read more {">"}
                    </Link>

                    <div style={{ flex: 1 }}></div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="LoadMoreOuter">
            {listings.length > 0 && (
              <Waypoint scrollableAncestor={window} onEnter={handleEndReached}>
                {isFetchingMore ? (
                  <div
                    className="LoadMoreOuter"
                    style={{ ...globalTheme.backgroundMainOffBeige }}
                  >
                    <CircularProgress size={30} color="inherit" />
                  </div>
                ) : (
                  <div className="LoadMoreOuter">
                    <p
                      className="AdminDescription"
                      style={{ ...globalTheme.backgroundMainOffBeige }}
                    ></p>
                  </div>
                )}
              </Waypoint>
            )}
          </div>
        </div>
      ) : (
        <div className="AdminListingsLoading">
          <p
            className="AdminDescription"
            style={{ ...globalTheme.backgroundMainOffBeige }}
          >
            No featured listings found
          </p>
        </div>
      )}
      <Dialog
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          },
        }}
        onClose={() => {
          adminState.setActiveListingName("");
          adminState.setActiveListing("");
        }}
        open={listingDialogOpen}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={() => {
          adminState.setActiveListingName("");
          adminState.setActiveListing("");
        }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent
          sx={{
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: "50px",
          }}
        >
          {adminState.activeListing !== "" && (
            <AdminListingDialog
              listingId={adminState.activeListing}
              goToDialog={"featured"}
              listingName={adminState.activeListingName}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
