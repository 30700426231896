import React, { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ButtonBase, TextField } from "@mui/material";
import aa from "search-insights";
import Autocomplete from "@mui/material/Autocomplete";
import { categoryEnums } from "../../utils/enums";
import { useNavigate } from "react-router-dom";
import {
  useExploreSortState,
  useDeviceState,
  useThemeState,
} from "../../utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/auth";
import "./TopBoxes.css";

export default function SearchAndExplore() {
  const [user] = useAuthState(firebaseAuth);
  const [showInfo, setShowInfo] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
  const [query, setQuery] = useState("");
  const exploreSortState = useExploreSortState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const navigate = useNavigate();

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("exploreInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("exploreInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("exploreInfo");
    setShowInfo(true);
  };

  // Randomize 5 search suggestions from category enums names, every suggestion has to be unique
  useEffect(() => {
    const suggestions: string[] = [];
    while (suggestions.length < 5) {
      const random = Math.floor(Math.random() * categoryEnums.length);
      if (!suggestions.includes(categoryEnums[random].displayname)) {
        suggestions.push(categoryEnums[random].displayname);
      }
    }
    setSearchSuggestions(suggestions);
  }, []);

  const onResultClick = async (
    id: string,
    index: number,
    searchId: string
  ) => {
    navigate(`/listings/page/${id}`);
    try {
      await aa("clickedObjectIDsAfterSearch", {
      index: "gath3r_listings",
      eventName: "Listing Clicked after Search",
      queryID: searchId,
      objectIDs: [id],
      positions: [index + 1],
    });
    } catch (error) {
      console.error(error);
      console.log(error)
    }
  };

  useEffect(() => {
    setQuery(exploreSortState.search);
  }, [exploreSortState.search]);

  const styles = {
    exploreSearchForm: {
      "& .MuiOutlinedInput-root": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        backgroundColor: globalTheme.figmaWhite.color,
        boxShadow: "none",
        borderRadius: "8px",
        padding: "6px",
        paddingLeft: "14px",
        paddingRight: "14px !important",
        "& fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&:hover fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          color: globalTheme.figmaPrimaryText.color,
        },
      },
      "& label.Mui-focused": {
        color: globalTheme.figmaPrimaryText.color,
      },
      "& label": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
  };

  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.primarySurface.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
      }}
    >
      {!showInfo && (
        <InfoIcon
          onClick={onInfoOpen}
          sx={{
            color: globalTheme.textMainBlack.color,
            cursor: "pointer",
            position: "absolute",
            right: "6px",
            top: "6px",
            fontSize: "17px",
            "@media (min-width: 350px)": {
              fontSize: "18px",
            },
            "@media (min-width: 550px)": {
              fontSize: "19px",
            },
            "@media (min-width: 700px)": {
              fontSize: "20px",
            },
          }}
        />
      )}
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.textMainBlack.color }}
        >
          Search & Explore
        </p>
        <p className="SearchAndExploreHeaderSub" style={{ color: globalTheme.textMainBlack.color }}>
          Find what you are looking for and so much more.
        </p>
      </div>
      {deviceState.isMobile ? (
        <Autocomplete
          freeSolo
          fullWidth
          inputValue={query}
          sx={{
            ...styles.exploreSearchForm,
          }}
          filterOptions={(x) => x}
          getOptionLabel={(option: any) => {
            return option.name;
          }}
          renderOption={(props: any, option: any) => {
            return (
              query !== "" && (
                <ButtonBase
                  key={option.id}
                  onClick={() => {
                    onResultClick(
                option.id,
                props["data-option-index"],
                option.searchId
              );
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <p className="AutocompleteResultTitle">{option.name}</p>
                  <p className="AutocompleteResultText">
                    {"in " + option.category}
                  </p>
                </ButtonBase>
              )
            );
          }}
          options={exploreSortState.results}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              onChange={(event) => {
                exploreSortState.setSortType("relevance");
                exploreSortState.setSearch(event.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon
                      style={{ color: globalTheme.figmaExploreSearch.color }}
                    />
                  </InputAdornment>
                ),
                endAdornment: exploreSortState.search !== "" && (
                  <InputAdornment position="end">
                    <CloseOutlinedIcon
                      style={{
                        color: globalTheme.figmaExploreSearch.color,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        exploreSortState.setSortType("createdAt");
                        exploreSortState.setSearch("");
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      ) : (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Search"
          value={exploreSortState.search}
          sx={styles.exploreSearchForm}
          onChange={(e) => {
            exploreSortState.setSortType("relevance");
            exploreSortState.setSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon
                  style={{ color: globalTheme.figmaExploreSearch.color }}
                />
              </InputAdornment>
            ),
            endAdornment: exploreSortState.search !== "" && (
              <InputAdornment position="end">
                <CloseOutlinedIcon
                  style={{
                    color: globalTheme.figmaExploreSearch.color,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    exploreSortState.setSortType("createdAt");
                    exploreSortState.setSearch("");
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.primarySurface.color,
            border: `1px solid ${globalTheme.textMainBlack.color}`,
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.textMainBlack.color }}
          >
            Select a category, sub category, and tag to fine tune your search
            results. Or search in sentences; “blockchain games on polygon”.
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.textMainBlack.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
      <div className="ExploreSearchSuggestionsOuter">
        <p
          className="ExploreSearchSuggestionsText"
          style={{
            padding: "3px",
            paddingLeft: "0px",
            marginRight: "10px",
            color: globalTheme.textMainBlack.color,
          }}
        >
          Search for:
        </p>
        {searchSuggestions.map((suggestion, index) => (
          <ButtonBase
            onClick={() => {
              exploreSortState.setSortType("relevance");
              exploreSortState.setSearch(suggestion);
            }}
            key={index}
            sx={{
              color: globalTheme.textMainBlack.color,
              border: `1px solid ${globalTheme.textMainBlack.color}`,
              marginRight: "10px",
              marginBottom: "10px",
              borderRadius: "12px",
              padding: "3px",
              paddingLeft: "10px",
              paddingRight: "10px",
              transition: "all 0.5s ease-in-out",
            }}
          >
            <p className="ExploreSearchSuggestionsText">{suggestion}</p>
          </ButtonBase>
        ))}
      </div>
    </div>
  );
}
