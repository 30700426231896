import React, { useEffect } from "react";
import logo from "./static/img/icon.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "./components/firebase/auth";
import { CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { firebaseAnalytics } from "./components/firebase/analytics";
import { logEvent } from "firebase/analytics";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Header from "./components/ui/appBar/Header";
import Start from "./views/start/Start";
import Listeners from "./components/listeners/Listeners";
import SnackbarListeners from "./components/listeners/SnackbarListeners";
import FirebaseListeners from "./components/listeners/FirebaseListeners";
import FirebaseHandlers from "./components/listeners/FirebaseHandlers";
import NotificationListeners from "./components/listeners/NotificationListeners";
import AchievementsListeners from "./components/listeners/AchievementsListener";
import ThemeListeners from "./components/listeners/ThemeListeners";

import {
  useDeviceState,
  useTabSettingsState,
  useThemeState,
  useAuthStates,
} from "./components/utils/globalStates";
import "./App.css";

function App() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const deviceState = useDeviceState();
  const authStates = useAuthStates((state) => state);
  const tabSettingsState = useTabSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const navigate = useNavigate();
  const location = useLocation();
  /*useEffect(() => {
    console.log("app open");
    logEvent(firebaseAnalytics, "app_open");
  }, []);*/

  useEffect(() => {
    if (
      user === null &&
      !loading &&
      location.pathname !== "/auth/login" &&
      location.pathname !== "/auth/register" &&
      location.pathname !== "/auth/verify" &&
      location.pathname !== "/auth"
    ) {
      return navigate("/auth/login");
    }
  }, [user, location.pathname, loading]);

  useEffect(() => {
    if (authStates.userData === null || authStates.userData === undefined)
      return;
    if (
      authStates.userData.displayName === null ||
      authStates.userData.displayName === undefined ||
      authStates.userData.displayName === ""
    ) {
      navigate("/auth/displayname");
    }
  }, [authStates.userData]);

  return user === null && loading ? (
    <div
      className="App"
      style={{ backgroundColor: globalTheme.figmaBeigeLight.color }}
    >
      <CircularProgress sx={{ color: globalTheme.figmaPrimaryColor }} />
    </div>
  ) : (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        className="App"
        style={{ backgroundColor: globalTheme.figmaBeigeLight.color }}
      >
        <div
          style={{
            backgroundColor: tabSettingsState.color,
            width: "100%",
            height: deviceState.isNotificationOpen ? "90px" : "0px",
            transition: "height 0.3s ease",
          }}
        ></div>
        {user !== null && user !== undefined && authStates.userData !== null && authStates.userData !== undefined && <NotificationListeners />}
        {user !== null && user !== undefined && authStates.userData !== null && authStates.userData !== undefined && <AchievementsListeners />}
        {user !== null && <Header />}
        <Start />

        <Listeners />
        <SnackbarListeners />
        <ThemeListeners />
        {user !== null && user !== undefined && <FirebaseListeners />}
        {user !== null && user !== undefined && <FirebaseHandlers />}
      </div>
    </LocalizationProvider>
  );
}

export default App;
