import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import EastIcon from "@mui/icons-material/East";

import Rating from "@mui/material/Rating";
import { Avatar, Skeleton } from "@mui/material";
import { LazyLoadImg } from "../../../../components/ui/misc/LazyLoadImg";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./DashWidgetsComp.css";

moment().format();
export default function ListingsWidgetItem({
  dataToRender,
  isLink,
}: {
  dataToRender: any;
  isLink: boolean;
}) {
  const navigate = useNavigate();
  const globalTheme = useThemeState((state) => state.globalTheme);
  
const onLinkClick = () => {
  if (isLink) {
    navigate(`/listings/page/${dataToRender.id}`);
  }
}
  return dataToRender === null ? (
    <Skeleton
      variant="rectangular"
      style={{
        width: "100%",
        height: "30px",
        borderRadius: "8px",
        marginBottom: "3px",
        marginTop: "3px",
      }}
    />
  ) : (
    <div
    onClick={onLinkClick}
      className="ListingsWidgetItem"
      style={{
        color: globalTheme.figmaPrimaryText.color,
      }}
    >
      {dataToRender.logo !== "" &&
      dataToRender.logo !== undefined &&
      dataToRender.logo !== null ? (
        <div
          className="ListingsWidgetItemAvatar"
        >
          <LazyLoadImg imgUrl={dataToRender.logo} radiusStyle={'50%'} />
        </div>
      ) : (
        <div className="ListingsWidgetItemAvatar">
          <Avatar
            sx={{
              bgcolor: globalTheme.figmaWhite.color,
              color: globalTheme.figmaSecondaryText.color,
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              fontSize: "5px",
              textDecoration: "none",
              "@media (min-width: 350px)": {
                fontSize: "8px",
              },
              "@media (min-width: 550px)": {
                fontSize: "10px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
            alt={dataToRender.name}
          >
            {dataToRender.name.substr(0, 2)}
          </Avatar>
        </div>
      )}
      {dataToRender.type === "createdAt" && (
        <div className="ListingsWidgetItemInner">
          <p
            className="ListingsWidgetItemTitle"
            style={{
              color: globalTheme.figmaPrimaryText.color,
            }}
          >
            {dataToRender.name}
            <span
              style={{
                color: globalTheme.figmaPrimaryText.color,
                fontSize: "75%",
                marginLeft: "1px",
                fontWeight: "normal",
                marginTop: "1px",
              }}
            >
              {" "}
              added to{" "}
              <span
                style={{
                  color: globalTheme.figmaLinks.color,
                }}
              >
                {dataToRender.category}
              </span>
            </span>
          </p>
          <p
            className="ListingsWidgetItemSub"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            {moment(dataToRender.createdAt).fromNow()}
          </p>
        </div>
      )}
      {dataToRender.type === "rating" && (
        <div className="ListingsWidgetItemInner">
          <div className="ListingsWidgetItemTitleOuter">
            <p
              className="ListingsWidgetItemTitle"
              style={{
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              {dataToRender.name}
            </p>
            {dataToRender.rating > 0 ? (
              <div className="RatingItemRating" style={{ marginLeft: "10px" }}>
                <p
                  className="RatingItemRatingText"
                  style={{
                    color: globalTheme.figmaPrimaryTextSub.color,
                    marginTop: "1px",
                  }}
                >
                  {dataToRender.rating.toFixed(1)}
                </p>
                <Rating
                  value={dataToRender.rating}
                  readOnly
                  size="small"
                  sx={{
                    "& svg": { color: globalTheme.figmaPrimaryText.color },
                  }}
                />
              </div>
            ) : (
              <div className="RatingItemRating">
                <div
                  className="RatingItemRatingText"
                  style={{ color: globalTheme.figmaPrimaryTextSub.color }}
                >
                  Be the first to rate or review
                </div>
              </div>
            )}
          </div>
          <div
            className="ListingsWidgetItemTitleOuter"
            style={{ marginBottom: "0px" }}
          >
            {dataToRender.ratingCount > 0 && (
              <p
                className="RatingItemRatingText"
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginLeft: "0px",
                }}
              >
                {dataToRender.ratingCount} Ratings
              </p>
            )}
            {dataToRender.reviewsCount > 0 && dataToRender.ratingCount > 0 && (
              <p
                className="RatingItemRatingText"
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginLeft: "3px",
                }}
              >
                -
              </p>
            )}
            {dataToRender.reviewsCount > 0 && (
              <p
                className="RatingItemRatingText"
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginLeft: "3px",
                }}
              >
                {dataToRender.reviewsCount} Reviews
              </p>
            )}
          </div>
        </div>
      )}
      {dataToRender.type === "trendingHour" && (
        <div className="ListingsWidgetItemInner">
          <div className="ListingsWidgetItemTitleOuter">
            <p
              className="ListingsWidgetItemTitle"
              style={{
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              {dataToRender.name}
            </p>
            <div
              className="ListingsPageTrending"
              style={{
                justifyContent: "flex-start",
                marginLeft: "5px",
                marginBottom: "1px",
              }}
            >
              {dataToRender.trendingHour > 150 && (
                <NorthEastIcon
                  sx={{
                    color: "#00c43e",
                    fontSize: "14px",
                    marginRight: "2px",
                  }}
                />
              )}
              {dataToRender.trendingHour < 50 && (
                <SouthEastIcon
                  sx={{
                    color: "#c40000",
                    fontSize: "14px",
                    marginRight: "2px",
                  }}
                />
              )}
              {dataToRender.trendingHour >= 50 &&
                dataToRender.trendingHour <= 150 && (
                  <EastIcon
                    color="inherit"
                    sx={{ fontSize: "14px", marginRight: "2px" }}
                  />
                )}
              <p
                className="ListingsPageTopFooterSub"
                style={{ color: globalTheme.figmaPrimaryText.color }}
              >
                {dataToRender.trendingHour < 1000 &&
                  dataToRender.trendingHour + "%"}
                {dataToRender.trendingHour >= 1000 && "> 1000%"}
              </p>
            </div>
          </div>
          <p
            className="ListingsWidgetItemSub"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            {dataToRender.viewsDay !== undefined &&
            dataToRender.viewsDay !== null &&
            dataToRender.viewsDay > 0
              ? dataToRender.viewsDay + " views today"
              : "No views today"}
          </p>
        </div>
      )}
      {dataToRender.type === "viewsTotal" && (
        <div className="ListingsWidgetItemInner">
          <div className="ListingsWidgetItemTitleOuter">
            <p
              className="ListingsWidgetItemTitle"
              style={{
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              {dataToRender.name}
            </p>
          </div>
          <p
            className="ListingsWidgetItemSub"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            {dataToRender.viewsDay !== undefined &&
            dataToRender.viewsDay !== null &&
            dataToRender.viewsDay > 0
              ? dataToRender.viewsDay + " views today"
              : "No views today"}{" "}
            {dataToRender.viewsTotal !== undefined &&
            dataToRender.viewsTotal !== null &&
            dataToRender.viewsTotal > 0
              ? "- " + dataToRender.viewsTotal + " total views"
              : "- No total views"}
          </p>
        </div>
      )}
    </div>
  );
}
