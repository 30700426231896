import React, { useState, useEffect } from "react";
import {
  useExploreSortState,
  useThemeState,
} from "../../../components/utils/globalStates";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import "./TokensList.css";

moment().format();

export default function TokensListItem({
  row,
  screenWidth,
}: {
  row: any;
  screenWidth: number;
}) {
  const [open, setOpen] = React.useState(false);
  const [chartTime, setChartTime] = useState("7d");
  const [chartData, setChartData] = useState<any>([]);
  const [chartDataTimes, setChartDataTimes] = useState<any>([]);
  const exploreSort = useExploreSortState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const navigate = useNavigate();

  const onChartClick = (time: string) => {
    setChartTime(time);
  };

  const onSearchClick = () => {
    exploreSort.setSearch(row.id);
    exploreSort.setSortType("relevance");
    exploreSort.setSortOrder("desc");
    exploreSort.setPage(0);
    navigate("/explore");
  };

  const onGoToTokenClick = () => {
    navigate(`/cryptos/page/${row.id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (chartTime === "7d") {
        let chartTimeNow = Date.now();
        let chartTimeThen = chartTimeNow - 604800000;
        let tempTimes = [];
        const tempValues = row.sparkline_in_7d.price;
        for (let i = 0; i < tempValues.length; i++) {
          tempTimes.push(
            moment(chartTimeThen + i * (604800000 / tempValues.length)).format(
              "YYYY-MM-DD HH:mm"
            )
          );
        }
        setChartDataTimes(tempTimes);
        setChartData(row.sparkline_in_7d.price);
      } else {
        try {
          let dateNow = Date.now();
          let dateThen = 0;
          let fetchUrl = "";
          if (chartTime === "1h") {
            dateThen = dateNow - 3600000;
            fetchUrl = `https://api.coincap.io/v2/assets/${row.id}/history?interval=m1&start=${dateThen}&end=${dateNow}`;
          } else if (chartTime === "24h") {
            dateThen = dateNow - 86400000;
            fetchUrl = `https://api.coincap.io/v2/assets/${row.id}/history?interval=m15&start=${dateThen}&end=${dateNow}`;
          } else if (chartTime === "30d") {
            dateThen = dateNow - 2592000000;
            fetchUrl = `https://api.coincap.io/v2/assets/${row.id}/history?interval=h1&start=${dateThen}&end=${dateNow}`;
          } else if (chartTime === "1y") {
            dateThen = dateNow - 31536000000;
            fetchUrl = `https://api.coincap.io/v2/assets/${row.id}/history?interval=d1&start=${dateThen}&end=${dateNow}`;
          }
          const response = await fetch(fetchUrl);
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          if (jsonResponse.error !== undefined && jsonResponse.error !== null) {
            setChartData([]);
            setChartDataTimes([]);
            return;
          }
          let data: any = [];
          let tempTimes = [];
          for (let i = 0; i < jsonResponse.data.length; i++) {
            data.push(Number(jsonResponse.data[i].priceUsd));
            tempTimes.push(
              moment(jsonResponse.data[i].time).format("YYYY-MM-DD HH:mm")
            );
          }
          setChartDataTimes(tempTimes);
          setChartData(data);
        } catch (error) {
          console.error(error);
          setChartData([]);
          setChartDataTimes([]);
        }
      }
    };
    fetchData();
  }, [chartTime]);

  const styles = {
    tableCell: {
      color: globalTheme.textMainBlack.color,
      fontSize: "8px",
      fontWeight: "bold",
      padding: "6px",
      paddingTop: "20px",
      "@media (min-width: 350px)": {
        fontSize: "9px",
      },
      "@media (min-width: 550px)": {
        fontSize: "10px",
      },
      "@media (min-width: 700px)": {
        fontSize: "11px",
      },
    },
    tableCellBody: {
      color: globalTheme.textMainBlack.color,
      fontSize: "9px",
      fontWeight: "400",
      padding: "6px",
      paddingTop: "15px",
      paddingBottom: "15px",
      "@media (min-width: 350px)": {
        fontSize: "10px",
      },
      "@media (min-width: 550px)": {
        fontSize: "11px",
      },
      "@media (min-width: 700px)": {
        fontSize: "12px",
      },
    },
    tableCellButton: {
      color: globalTheme.textMainGrey.color,
      paddingLeft: "0px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingRight: "0px",
    },
    tableContainer: {
      backgroundColor: globalTheme.boxBackgroundWhite.color,
      border: `1px solid ${globalTheme.textMainBlack.color}`,
      width: "90%",
      maxWidth: "800px",
      borderRadius: "8px",
      boxShadow: globalTheme.boxShadow,
      [`&.${tableCellClasses.head}`]: {
        fontSize: "26px",
      },
    },
  };

  return (
    <React.Fragment>
      <TableRow
        key={row.name}
        sx={{
          border: "unset !important",
          "& > *": { borderBottom: "none" },
        }}
      >
        <TableCell
          sx={{
            ...styles.tableCellBody,
            padding: 0,
            margin: 0,
            paddingLeft: "5px",
            fontWeight: "bold",
          }}
        >
          {row.market_cap_rank}
        </TableCell>
        <TableCell sx={{ ...styles.tableCellBody, padding: 0, margin: 0 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "20px",
              height: "20px",
              margin: "0px",
              marginLeft: "10px",
              marginRight: "5px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <LazyLoadImg imgUrl={row.image} />
          </div>
        </TableCell>
        <TableCell
          sx={{ ...styles.tableCellBody, fontWeight: "bold" }}
          component="th"
        >
          {row.name}
          <br />
          <span
            style={{
              fontSize: "90%",
              color: globalTheme.textMainGrey.color,
              fontWeight: "400",
            }}
          >
            {Number(row.market_cap).toLocaleString("en-US", {
              // add suffixes for thousands, millions, and billions
              // the maximum number of decimal places to use
              maximumFractionDigits: 2,
              // specify the abbreviations to use for the suffixes
              notation: "compact",
              compactDisplay: "short",
            })}
            $
          </span>
        </TableCell>
        <TableCell sx={styles.tableCellBody} align="right">
          {Number(row.current_price).toFixed(
            Number(row.current_price) < 10 ? 6 : 2
          )}
          $
        </TableCell>
        {screenWidth > 500 && (
          <TableCell sx={styles.tableCellBody} align="right">
            {Number(row.price_change_percentage_1h_in_currency).toFixed(2)}%
          </TableCell>
        )}
        <TableCell sx={styles.tableCellBody} align="right">
          {Number(row.price_change_percentage_24h).toFixed(2)}%
        </TableCell>
        {screenWidth > 500 && (
          <TableCell sx={styles.tableCellBody} align="right">
            {Number(row.price_change_percentage_7d_in_currency).toFixed(2)}%
          </TableCell>
        )}
        {screenWidth > 600 && (
          <TableCell sx={styles.tableCellBody} align="right">
            {Number(row.price_change_percentage_30d_in_currency).toFixed(2)}%
          </TableCell>
        )}
        {screenWidth > 800 && (
          <TableCell sx={styles.tableCellBody} align="right">
            {Number(row.price_change_percentage_1y_in_currency).toFixed(2)}%
          </TableCell>
        )}
        <TableCell sx={styles.tableCellButton} align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon
                sx={{ color: globalTheme.textMainBlack.color }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{ color: globalTheme.textMainBlack.color }}
              />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          border: "none !important",
        }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={
            screenWidth < 500
              ? 6
              : screenWidth < 600
              ? 8
              : screenWidth < 800
              ? 9
              : 10
          }
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div
              className="TokensListItemCollapsed"
              style={{
                backgroundColor: globalTheme.figmaGreenPastel.color,
                border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
                borderRadius: "8px",
              }}
            >
              <div className="TokensListItemCollapsedItem">
                {screenWidth < 500 && (
                  <div className="TokensListItemCollapsedItemInner">
                    <p className="TokensListItemCollapsedItemTile">1h %</p>
                    <p className="TokensListItemCollapsedItemSub">
                      {Number(
                        row.price_change_percentage_1h_in_currency
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}
                {screenWidth < 500 && (
                  <div className="TokensListItemCollapsedItemInner">
                    <p className="TokensListItemCollapsedItemTile">7d %</p>
                    <p className="TokensListItemCollapsedItemSub">
                      {Number(
                        row.price_change_percentage_7d_in_currency
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}

                {screenWidth < 600 && (
                  <div className="TokensListItemCollapsedItemInner">
                    <p className="TokensListItemCollapsedItemTile">30d %</p>
                    <p className="TokensListItemCollapsedItemSub">
                      {Number(
                        row.price_change_percentage_30d_in_currency
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}

                {screenWidth < 800 && (
                  <div className="TokensListItemCollapsedItemInner">
                    <p className="TokensListItemCollapsedItemTile">1y %</p>
                    <p className="TokensListItemCollapsedItemSub">
                      {Number(
                        row.price_change_percentage_1y_in_currency
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">24h Low</p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.low_24h).toFixed(
                      Number(row.low_24h) < 10 ? 6 : 2
                    )}
                    $
                  </p>
                </div>
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">24h High</p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.high_24h).toFixed(
                      Number(row.high_24h) < 10 ? 6 : 2
                    )}
                    $
                  </p>
                </div>
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">
                    All Time High
                  </p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.ath).toFixed(Number(row.ath) < 10 ? 6 : 2)}$
                  </p>
                </div>
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">Market Cap</p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.market_cap).toLocaleString("en-US", {
                      // add suffixes for thousands, millions, and billions
                      // the maximum number of decimal places to use
                      maximumFractionDigits: 2,
                      // specify the abbreviations to use for the suffixes
                      notation: "compact",
                      compactDisplay: "short",
                    })}
                    $
                  </p>
                </div>
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">
                    Market Cap 24h
                  </p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.market_cap_change_24h).toLocaleString("en-US", {
                      // add suffixes for thousands, millions, and billions
                      // the maximum number of decimal places to use
                      maximumFractionDigits: 2,
                      // specify the abbreviations to use for the suffixes
                      notation: "compact",
                      compactDisplay: "short",
                    })}
                    $
                  </p>
                </div>
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">
                    Market Cap 24h
                  </p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.market_cap_change_percentage_24h).toFixed(2)}%
                  </p>
                </div>
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">Supply</p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.circulating_supply) === 0
                      ? "-"
                      : Number(row.circulating_supply).toLocaleString("en-US", {
                          // add suffixes for thousands, millions, and billions
                          // the maximum number of decimal places to use
                          maximumFractionDigits: 2,
                          // specify the abbreviations to use for the suffixes
                          notation: "compact",
                          compactDisplay: "short",
                        })}
                  </p>
                </div>
                <div className="TokensListItemCollapsedItemInner">
                  <p className="TokensListItemCollapsedItemTile">Max Supply</p>
                  <p className="TokensListItemCollapsedItemSub">
                    {Number(row.max_supply) === 0
                      ? "-"
                      : Number(row.max_supply).toLocaleString("en-US", {
                          // add suffixes for thousands, millions, and billions
                          // the maximum number of decimal places to use
                          maximumFractionDigits: 2,
                          // specify the abbreviations to use for the suffixes
                          notation: "compact",
                          compactDisplay: "short",
                        })}
                  </p>
                </div>
              </div>
              <div className="TokensListItemCollapsedItem">
                <div
                  style={{
                    border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
                    borderRadius: "8px",
                    backgroundColor: globalTheme.figmaYellowAlt.color,
                    width: "calc(100% - 20px)",
                    height: "100%",
                    minHeight: "110px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {chartData.length === 0 ? (
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <p className="TokensListItemCollapsedItemSub">NO DATA</p>
                    </div>
                  ) : (
                    <SparkLineChart
                      data={chartData}
                      height={100}
                      showTooltip
                      curve="natural"
                      valueFormatter={(value: number) =>
                        Number(value).toFixed(Number(value) < 10 ? 6 : 2) + "$"
                      }
                      xAxis={{
                        data: chartDataTimes,
                      }}
                    />
                  )}
                  <div
                    className="TokensListItemCollapsedChartButtons"
                    style={{
                      color: globalTheme.figmaPrimaryTextSub.color,
                    }}
                  >
                    <Button
                      onClick={() => onChartClick("1h")}
                      variant="text"
                      color="inherit"
                      size="small"
                      sx={{
                        maxWidth: "19%",
                        fontWeight: "bold",
                        padding: "0px",
                        fontSize: "12px",
                        minWidth: "15%",
                        borderRadius: "8px",
                        border:
                          chartTime === "1h"
                            ? `1px solid ${globalTheme.figmaPrimaryTextSub.color}`
                            : "none",
                      }}
                    >
                      1h
                    </Button>
                    <Button
                      onClick={() => onChartClick("24h")}
                      variant="text"
                      color="inherit"
                      size="small"
                      sx={{
                        maxWidth: "19%",
                        fontWeight: "bold",
                        padding: "0px",
                        fontSize: "12px",
                        minWidth: "15%",
                        borderRadius: "8px",
                        border:
                          chartTime === "24h"
                            ? `1px solid ${globalTheme.figmaPrimaryTextSub.color}`
                            : "none",
                      }}
                    >
                      24h
                    </Button>
                    <Button
                      onClick={() => onChartClick("7d")}
                      variant="text"
                      color="inherit"
                      size="small"
                      sx={{
                        maxWidth: "19%",
                        fontWeight: "bold",
                        padding: "0px",
                        fontSize: "12px",
                        minWidth: "15%",
                        borderRadius: "8px",
                        border:
                          chartTime === "7d"
                            ? `1px solid ${globalTheme.figmaPrimaryTextSub.color}`
                            : "none",
                      }}
                    >
                      7d
                    </Button>
                    <Button
                      onClick={() => onChartClick("30d")}
                      variant="text"
                      color="inherit"
                      size="small"
                      sx={{
                        maxWidth: "19%",
                        fontWeight: "bold",
                        padding: "0px",
                        fontSize: "12px",
                        minWidth: "15%",
                        borderRadius: "8px",
                        border:
                          chartTime === "30d"
                            ? `1px solid ${globalTheme.figmaPrimaryTextSub.color}`
                            : "none",
                      }}
                    >
                      30d
                    </Button>
                    <Button
                      onClick={() => onChartClick("1y")}
                      variant="text"
                      color="inherit"
                      size="small"
                      sx={{
                        maxWidth: "19%",
                        fontWeight: "bold",
                        padding: "0px",
                        fontSize: "12px",
                        minWidth: "15%",
                        borderRadius: "8px",
                        border:
                          chartTime === "1y"
                            ? `1px solid ${globalTheme.figmaPrimaryTextSub.color}`
                            : "none",
                      }}
                    >
                      1y
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="TokensListItemCollapsedButtons"
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={onSearchClick}
                >
                  <SearchOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={onGoToTokenClick}
                >
                  <LaunchOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  <BookmarkBorderOutlinedIcon />
                </IconButton>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
