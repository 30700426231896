import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Rating from "@mui/material/Rating";
import { useNavigate, Link } from "react-router-dom";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { doc, getDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import ReportIcon from "@mui/icons-material/Report";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonBase } from "@mui/material";
import copy from "copy-to-clipboard";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useThemeState,
} from "../../../components/utils/globalStates";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { categoryEnums } from "../../../components/utils/enums";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { primaryColorTextField } from "../../../components/theme/globalMuiTheme";
import Pfp from "../../../components/ui/img/Pfp";
import { PfpViewer } from "../../../components/ui/img/PfpViewer";
import { standardDialogTitle } from "../../../components/theme/globalMuiTheme";
import "./ReviewsComp.css";

moment().format();

export default function ReviewItem({
  reviewItem,
  isListing,
  isUser,
}: {
  reviewItem: any;
  isListing: boolean;
  isUser: boolean;
}) {
  const [authorData, setAuthorData] = useState<any | null>(null);
  const [authorLoading, setAuthorLoading] = useState(true);
  const [listingData, setListingData] = useState<any | null>(null);
  const [listingLoading, setListingLoading] = useState(true);
  const [voteUpdating, setVoteUpdating] = useState(false);
  const [userVoted, setUserVoted] = useState(false);
  const [userVote, setUserVote] = useState(true);
  const [reportUpdating, setReportUpdating] = useState(false);
  const [hasReported, setHasReported] = useState(false);
  const [reportOverlay, setReportOverlay] = useState(false);
  const [upvoteUpdating, setUpvoteUpdating] = useState(false);
  const [downvoteUpdating, setDownvoteUpdating] = useState(false);
  const [upvoteDeleting, setUpvoteDeleting] = useState(false);
  const [downvoteDeleting, setDownvoteDeleting] = useState(false);
  const [user, loading, error] = useAuthState(firebaseAuth);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [reasonString, setReasonString] = useState("");
  const [reasonArray, setReasonArray] = useState<string[]>([]);

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setReasonArray([...reasonArray, event.target.value]);
    } else {
      setReasonArray(
        reasonArray.filter((reason) => reason !== event.target.value)
      );
    }
  };
  // Update reasonString based on reasonArray
  useEffect(() => {
    setReasonString(reasonArray.join(","));
  }, [reasonArray]);

  const onShareClick = () => {
    copy(`https://app.gath3r.co/reviews/listing/${reviewItem.listing}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  // Get author if is listing
  useEffect(() => {
    if (!isListing) {
      setAuthorLoading(false);
      return;
    }
    if (
      reviewItem.uid === null ||
      reviewItem.uid === undefined ||
      reviewItem.uid === ""
    ) {
      return;
    }
    const getData = async () => {
      try {
        const userDoc = await getDoc(
          doc(firestoreDb, "publicUserInfo", reviewItem.uid)
        );
        if (userDoc.exists()) {
          if (
            userDoc.data().displayName !== undefined &&
            userDoc.data().displayName !== null
          ) {
            setAuthorData(userDoc.data());
            setAuthorLoading(false);
          } else {
            setAuthorData(null);
            setAuthorLoading(false);
          }
        } else {
          setAuthorData(null);
          setAuthorLoading(false);
        }
      } catch (error) {
        console.error("Error getting author name:", error);
        setAuthorData(null);
        setAuthorLoading(false);
      }
    };
    getData();
  }, [reviewItem.uid]);

  // Get listing if is user
  useEffect(() => {
    if (!isUser) {
      setListingLoading(false);
      return;
    }
    if (
      reviewItem.listing === null ||
      reviewItem.listing === undefined ||
      reviewItem.listing === ""
    ) {
      return;
    }
    const getData = async () => {
      try {
        const listingDoc = await getDoc(
          doc(firestoreDb, "listings", reviewItem.listing)
        );
        if (listingDoc.exists()) {
          if (
            listingDoc.data().name !== undefined &&
            listingDoc.data().name !== null
          ) {
            setListingData(listingDoc.data());
            setListingLoading(false);
          } else {
            setListingData(null);
            setListingLoading(false);
          }
        } else {
          setListingData(null);
          setListingLoading(false);
        }
      } catch (error) {
        console.error("Error getting listing name:", error);
        setListingData(null);
        setListingLoading(false);
      }
    };
    getData();
  }, [reviewItem.listing]);

  // check if user has voted in reviewVotes collection
  useEffect(() => {
    if (user === null || user === undefined) {
      return;
    }
    const checkUserVote = async () => {
      try {
        const userVoteDoc = await getDoc(
          doc(firestoreDb, "reviewVotes", user.uid + "--" + reviewItem.id)
        );
        if (userVoteDoc.exists()) {
          setUserVoted(true);
          setUserVote(userVoteDoc.data().vote);
        } else {
          setUserVoted(false);
        }
      } catch (error) {
        console.error("Error getting user vote:", error);
        setUserVoted(false);
      }
    };
    checkUserVote();
  }, [user, reviewItem.id]);

  // Check if user has reported review
  useEffect(() => {
    if (reportOverlay === false) {
      return;
    }
    if (user === null || user === undefined) {
      return;
    }
    const checkUserReport = async () => {
      try {
        const userReportDoc = await getDoc(
          doc(firestoreDb, "reviewReports", user.uid + "--" + reviewItem.id)
        );
        if (userReportDoc.exists()) {
          setReasonArray(userReportDoc.data().reason.split(","));
          setHasReported(true);
        } else {
          setReasonArray([]);
          setHasReported(false);
        }
      } catch (error) {
        console.error("Error getting user report:", error);
        setReasonArray([]);
        setHasReported(false);
      }
    };
    checkUserReport();
  }, [user, reviewItem.id, reportOverlay]);

  // Vote on review
  const voteOnReview = async (vote: boolean) => {
    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to vote");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (vote === true) {
      setUpvoteUpdating(true);
    }
    if (vote === false) {
      setDownvoteUpdating(true);
    }
    setVoteUpdating(true);
    const voteOnReviewCallable = httpsCallable(
      firebaseFunctions,
      "reviews-new_vote"
    );
    const idToken = await user.getIdToken();
    try {
      await voteOnReviewCallable({
        id: reviewItem.id,
        vote: vote,
        token: idToken,
      });
      setVoteUpdating(false);
      setUserVoted(true);
      setUserVote(vote);
      setUpvoteUpdating(false);
      setDownvoteUpdating(false);
      snackbarStates.setMessage("Vote submitted");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.error("Error voting on review:", error);
      snackbarError.setMessage("Error voting on review, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setVoteUpdating(false);
      setUpvoteUpdating(false);
      setDownvoteUpdating(false);
    }
  };

  // Delete vote on review
  const deleteVoteOnReview = async () => {
    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to delete vote");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (userVote === true) {
      setUpvoteDeleting(true);
    }
    if (userVote === false) {
      setDownvoteDeleting(true);
    }
    setVoteUpdating(true);
    const deleteVoteOnReviewCallable = httpsCallable(
      firebaseFunctions,
      "reviews-delete_vote"
    );
    const idToken = await user.getIdToken();
    try {
      await deleteVoteOnReviewCallable({
        id: user.uid + "--" + reviewItem.id,
        token: idToken,
      });
      setVoteUpdating(false);
      setUserVoted(false);
      setUpvoteDeleting(false);
      setDownvoteDeleting(false);
      snackbarStates.setMessage("Vote deleted");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.error("Error deleting vote on review:", error);
      snackbarError.setMessage(
        "Error deleting vote on review, please try again"
      );
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setVoteUpdating(false);
      setUpvoteDeleting(false);
      setDownvoteDeleting(false);
    }
  };

  // Report review
  const reportReview = async () => {
    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to report review");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (reasonString === "") {
      snackbarError.setMessage("Please enter a reason to report review");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    setReportUpdating(true);
    setReportOverlay(false);
    snackbarStates.setMessage("Reporting review");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);
    const reportReviewCallable = httpsCallable(
      firebaseFunctions,
      "reviews-new_report"
    );
    const idToken = await user.getIdToken();
    try {
      await reportReviewCallable({
        id: reviewItem.id,
        reason: reasonString,
        token: idToken,
      });
      setReportUpdating(false);
      setHasReported(true);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Review reported");
      snackbarStates.setOpenLength(3000);
    } catch (error) {
      console.error("Error reporting review:", error);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage("Error reporting review, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setReportUpdating(false);
      setReportOverlay(true);
    }
  };

  const styles = {
    formGroup: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "0px",
    },
    checkBox: {
      "&.Mui-checked": {
        color: globalTheme.solidMainBlack.color,
      },
      color: globalTheme.solidMainBlack.color,
    },
    formControl: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      margin: "0px",
      width: "33%",
      "@media (max-width: 800px)": {
        width: "50%",
      },

      "& .MuiTypography-root": {
        fontSize: "11px !important",
        "@media (min-width: 350px)": {
          fontSize: "12px !important",
        },
        "@media (min-width: 550px)": {
          fontSize: "13px !important",
        },
        "@media (min-width: 700px)": {
          fontSize: "14px !important",
        },
      },
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="UserReviewsItem"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        boxShadow: globalTheme.boxShadow.boxShadow,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
      }}
    >
      {isListing &&
        !reportOverlay &&
        (authorLoading ? (
          <div
            className="ReviewsItemUserHeader"
            style={{ pointerEvents: reportOverlay ? "none" : "auto" }}
          >
            <Skeleton
              variant="circular"
              style={{ borderRadius: "6px", width: "30px", height: "30px" }}
            />
            <div className="ReviewsItemUserHeaderInner">
              <Skeleton
                variant="text"
                style={{ fontSize: "14px", marginBottom: "0px", width: "50px" }}
              />
              <Skeleton
                variant="text"
                style={{ fontSize: "11px", marginBottom: "0px", width: "80px" }}
              />
            </div>
          </div>
        ) : (
          authorData !== null && (
            <div
              className="ReviewsItemUserHeader"
              style={{ pointerEvents: reportOverlay ? "none" : "auto" }}
            >
              <div className="ReviewsItemUserHeaderAvatar">
                {authorData.pfpString === null ||
                authorData.pfpString === undefined ||
                authorData.pfpString === "" ? (
                  <div className="ReviewsItemUserHeaderAvatarImg">
                    <PfpViewer pfpString={""} randomize={true} />
                  </div>
                ) : (
                  <div className="ReviewsItemUserHeaderAvatarImg">
                    <PfpViewer
                      pfpString={authorData.pfpString}
                      randomize={false}
                    />
                  </div>
                )}
              </div>
              <div className="ReviewsItemUserHeaderInner">
                <Link
                  to={`/users/page/${reviewItem.uid}`}
                  className="ReviewsItemUserHeaderInnerName"
                  style={{ color: globalTheme.textMainBlack.color }}
                >
                  {authorData.displayName === ""
                    ? "Anonymous user"
                    : authorData.displayName}
                </Link>
                <p
                  className="ReviewsItemUserHeaderInnerSub"
                  style={{ color: globalTheme.textMainGrey.color }}
                >
                  {authorData.reviewsCount !== undefined &&
                    authorData.reviewsCount !== null &&
                    authorData.reviewsCount > 0 && (
                      <Link
                        to={`/reviews/user/${reviewItem.uid}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {authorData.reviewsCount} reviews
                      </Link>
                    )}
                  {authorData.reviewsCount !== undefined &&
                    authorData.reviewsCount !== null &&
                    authorData.reviewsCount > 0 &&
                    authorData.ratingCount !== undefined &&
                    authorData.ratingCount !== null &&
                    authorData.ratingCount > 0 && <span> - </span>}
                  {authorData.ratingCount !== undefined &&
                    authorData.ratingCount !== null &&
                    authorData.ratingCount > 0 && (
                      <Link
                        to={`/reviews/user/rating/${reviewItem.uid}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {authorData.ratingCount} ratings
                      </Link>
                    )}
                </p>
              </div>
            </div>
          )
        ))}
      {isUser &&
        !reportOverlay &&
        (listingLoading ? (
          <div
            className="ReviewsItemUserHeader"
            style={{ pointerEvents: reportOverlay ? "none" : "auto" }}
          >
            <Skeleton
              variant="circular"
              style={{ borderRadius: "6px", width: "30px", height: "30px" }}
            />
            <div className="ReviewsItemUserHeaderInner">
              <Skeleton
                variant="text"
                style={{ fontSize: "14px", marginBottom: "0px", width: "50px" }}
              />
              <Skeleton
                variant="text"
                style={{ fontSize: "11px", marginBottom: "0px", width: "80px" }}
              />
            </div>
          </div>
        ) : (
          listingData !== null && (
            <div
              className="ReviewsItemUserHeader"
              style={{ pointerEvents: reportOverlay ? "none" : "auto" }}
            >
              <div className="ReviewsItemUserHeaderAvatar">
                {listingData.logo !== null &&
                listingData.logo !== undefined &&
                listingData.logo !== "" ? (
                  <img
                    className="ReviewsItemUserHeaderAvatarImg"
                    src={listingData.logo}
                    alt="Profile"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: globalTheme.boxBackgroundWhite.color,
                      color: globalTheme.textMainBlack.color,
                      width: "65px",
                      height: "65px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      "@media (min-width: 350px)": {
                        width: "75px",
                        height: "75px",
                        fontSize: "17px",
                      },
                      "@media (min-width: 550px)": {
                        width: "85px",
                        height: "85px",
                        fontSize: "18px",
                      },
                      "@media (min-width: 700px)": {
                        width: "95px",
                        height: "95px",
                        fontSize: "20px",
                      },
                    }}
                    alt={listingData.name}
                  >
                    {listingData.name.substr(0, 2)}
                  </Avatar>
                )}
              </div>
              <div className="ReviewsItemUserHeaderInner">
                <Link
                  to={`/listings/page/${reviewItem.listing}`}
                  className="ReviewsItemUserHeaderInnerName"
                  style={{ color: globalTheme.textMainBlack.color }}
                >
                  {listingData.name !== null &&
                    listingData.name !== undefined &&
                    listingData.name !== "" &&
                    listingData.name}
                </Link>
                <p
                  className="ReviewsItemUserHeaderInnerSub"
                  style={{ color: globalTheme.textMainGrey.color }}
                >
                  {listingData.reviewsCount !== undefined &&
                    listingData.reviewsCount !== null &&
                    listingData.reviewsCount > 0 && (
                      <Link
                        to={`/reviews/listing/${reviewItem.listing}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {listingData.reviewsCount} reviews
                      </Link>
                    )}
                  {listingData.reviewsCount !== undefined &&
                    listingData.reviewsCount !== null &&
                    listingData.reviewsCount > 0 &&
                    listingData.ratingCount !== undefined &&
                    listingData.ratingCount !== null &&
                    listingData.ratingCount > 0 && <span> - </span>}
                  {listingData.ratingCount !== undefined &&
                    listingData.ratingCount !== null &&
                    listingData.ratingCount > 0 && (
                      <Link
                        to={`/reviews/listing/rating/${reviewItem.listing}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {listingData.ratingCount} ratings
                      </Link>
                    )}
                </p>
              </div>
            </div>
          )
        ))}
      {!reportOverlay && (
        <div
          className="ReviewsItemInner"
          style={{ pointerEvents: reportOverlay ? "none" : "auto" }}
        >
          <p
            className="UserReviewsItemTitle"
            style={{ color: globalTheme.textMainBlack.color }}
          >
            {reviewItem.title}
          </p>
          <div className="ReviewsItemRatingOuter">
            <p
              className="ReviewsItemUserHeaderInnerSub"
              style={{
                color: globalTheme.textMainBlack.color,
                margin: "0px",
                marginLeft: "2px",
                marginRight: "10px",
                width: "unset",
                fontWeight: 300,
              }}
            >
              {reviewItem.rating}
            </p>
            <Rating value={reviewItem.rating} readOnly size="small" />
            <p
              className="ReviewsItemUserHeaderInnerSub"
              style={{
                color: globalTheme.textMainGrey.color,
                margin: "0px",
                marginLeft: "10px",
                width: "unset",
                fontWeight: 300,
              }}
            >
              {moment(reviewItem.createdAt).fromNow()}
            </p>
          </div>
          <p
            className="UserReviewsItemText"
            style={{ color: globalTheme.textMainBlack.color }}
          >
            {reviewItem.text}
          </p>
        </div>
      )}

      {!reportOverlay && (
        <div
          className="UserRatingItemBottom"
          style={{ pointerEvents: reportOverlay ? "none" : "auto" }}
        >
          <ButtonBase
            disabled={voteUpdating}
            onClick={() =>
              userVoted && userVote ? deleteVoteOnReview() : voteOnReview(true)
            }
            sx={{
              textDecoration: "none",
              color: globalTheme.textMainGrey.color,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {userVoted === true && userVote === true ? (
              <ThumbUpIcon
                sx={{
                  color: globalTheme.textMainGrey.color,
                  fontSize: "13px",
                  "@media (min-width: 350px)": {
                    fontSize: "15px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "18px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "21px",
                  },
                }}
              />
            ) : (
              <ThumbUpAltOutlinedIcon
                sx={{
                  color: globalTheme.textMainGrey.color,
                  fontSize: "13px",
                  "@media (min-width: 350px)": {
                    fontSize: "15px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "18px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "21px",
                  },
                }}
              />
            )}
            <p className="UserRatingItemBottomText">
              {upvoteDeleting
                ? "Deleting"
                : reviewItem.upVotes !== undefined &&
                  reviewItem.upVotes !== null &&
                  reviewItem.upVotes > 0
                ? reviewItem.upVotes + " People agree"
                : upvoteUpdating
                ? "Agreeing"
                : "Agree"}
            </p>
          </ButtonBase>
          <ButtonBase
            disabled={voteUpdating}
            onClick={() =>
              userVoted && userVote === false
                ? deleteVoteOnReview()
                : voteOnReview(false)
            }
            sx={{
              textDecoration: "none",
              color: globalTheme.textMainGrey.color,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            {userVoted === true && userVote === false ? (
              <ThumbDownIcon
                sx={{
                  color: globalTheme.textMainGrey.color,
                  fontSize: "13px",
                  "@media (min-width: 350px)": {
                    fontSize: "15px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "18px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "21px",
                  },
                }}
              />
            ) : (
              <ThumbDownAltOutlinedIcon
                sx={{
                  color: globalTheme.textMainGrey.color,
                  fontSize: "13px",
                  "@media (min-width: 350px)": {
                    fontSize: "15px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "18px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "21px",
                  },
                }}
              />
            )}
            <p className="UserRatingItemBottomText">
              {reviewItem.downVotes !== undefined &&
              reviewItem.downVotes !== null &&
              reviewItem.downVotes > 0
                ? reviewItem.downVotes + " People disagree"
                : downvoteUpdating
                ? "Disagreeing"
                : downvoteDeleting
                ? "Deleting"
                : "Disagree"}
            </p>
          </ButtonBase>
          <ButtonBase
            onClick={onShareClick}
            style={{
              textDecoration: "none",
              color: globalTheme.textMainGrey.color,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "6px",
              marginRight: "6px",
              flex: 1,
              textAlign: "center",
            }}
          >
            <ShareOutlinedIcon
              sx={{
                color: globalTheme.textMainGrey.color,
                fontSize: "13px",
                "@media (min-width: 350px)": {
                  fontSize: "15px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "21px",
                },
              }}
            />
            <p
              className="UserRatingItemBottomText"
              style={{
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              Share
            </p>
          </ButtonBase>
          <ReportOutlinedIcon
            onClick={() => setReportOverlay(true)}
            sx={{
              cursor: "pointer",
              color: globalTheme.textMainGrey.color,
              fontSize: "15px",
              "@media (min-width: 350px)": {
                fontSize: "17px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "21px",
              },
            }}
          />
        </div>
      )}
      {reportOverlay && (
        <div
          className="ReviewsItemOverlay"
          style={{
            backgroundColor: globalTheme.boxBackgroundWhite.color,
            pointerEvents: reportOverlay ? "auto" : "none",
            paddingTop: reportOverlay ? "15px" : "0px",
            paddingBottom: reportOverlay ? "5px" : "0px",
          }}
        >
          {hasReported ? (
            <DialogTitle
              sx={{
                ...standardDialogTitle,
                width: "calc(100% - 8px)",
                marginLeft: "4px",
                lineHeight: "110%",
              }}
            >
              Already reported
            </DialogTitle>
          ) : (
            <DialogTitle
              sx={{
                ...standardDialogTitle,
                width: "calc(100% - 8px)",
                marginLeft: "4px",
                lineHeight: "110%",
              }}
            >
              Report Review
              <br />
              <span style={{ fontSize: "55%", fontWeight: 400 }}>
                Please select your reasons for reporting this entry.
              </span>
            </DialogTitle>
          )}
          <FormGroup sx={styles.formGroup} row>
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  disabled={hasReported}
                  size="small"
                  value={"spam"}
                  checked={reasonArray.includes("spam")}
                  onChange={handleReasonChange}
                />
              }
              label="Spam"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  disabled={hasReported}
                  value={"content"}
                  checked={reasonArray.includes("content")}
                  onChange={handleReasonChange}
                />
              }
              label="Inappropriate content"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  disabled={hasReported}
                  size="small"
                  value={"harassment"}
                  checked={reasonArray.includes("harassment")}
                  onChange={handleReasonChange}
                />
              }
              label="Harassment"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  disabled={hasReported}
                  size="small"
                  value={"imperonate"}
                  checked={reasonArray.includes("imperonate")}
                  onChange={handleReasonChange}
                />
              }
              label="Impersonation"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  disabled={hasReported}
                  size="small"
                  value={"falseInfo"}
                  checked={reasonArray.includes("falseInfo")}
                  onChange={handleReasonChange}
                />
              }
              label="False information"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  disabled={hasReported}
                  size="small"
                  value={"hate"}
                  checked={reasonArray.includes("hate")}
                  onChange={handleReasonChange}
                />
              }
              label="Hate speech"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  disabled={hasReported}
                  size="small"
                  value={"violence"}
                  checked={reasonArray.includes("violence")}
                  onChange={handleReasonChange}
                />
              }
              label="Violence"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  disabled={hasReported}
                  size="small"
                  value={"other"}
                  checked={reasonArray.includes("other")}
                  onChange={handleReasonChange}
                />
              }
              label="Other"
            />
          </FormGroup>
          <div className="AdminListDialogEditButtons" style={{marginBottom: '20px'}}>
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
                flex: "unset",
                minWidth: "90px",
              }}
               onClick={() => setReportOverlay(false)}
              disabled={reportUpdating}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                flex: "unset",
                marginLeft: "20px",
                minWidth: "90px",
              }}
              onClick={reportReview}
              disabled={
                hasReported || reportUpdating || reasonArray.length === 0
              }
            >
              {reportUpdating ? "Submitting..." : "Submit"}
            </ButtonBase>
          </div>
          
        </div>
      )}
    </div>
  );
}
