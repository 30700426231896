import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import aa from "search-insights";
import { Tooltip, IconButton } from "@mui/material";
import copy from "copy-to-clipboard";
import { useThemeState } from "../../utils/globalStates";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useExploreSortState,
  useAdminState,
} from "../../utils/globalStates";
import moment from "moment";
import { doc, onSnapshot, setDoc, deleteDoc } from "firebase/firestore";
import ProjectItemRating from "../../../views/listings/components/ProjectItemRating";
import ProjectRuggedBadge from "../img/badges/ProjectRuggedBadge";
import ProjectVerifiedBadge from "../img/badges/ProjectVerifiedBadge";
import { LazyLoadImg } from "../misc/LazyLoadImg";
import { firestoreDb } from "../../firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/auth";
import "./ListItems.css";

moment().format();

export default function ProjectListItem({
  listItem,
  indexKey,
  itemSize,
  clickAdmin,
}: any) {
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const exploreSortState = useExploreSortState();
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const adminState = useAdminState((state) => state);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);
  const [itemSizeState, setItemSizeState] = useState("large");

  useEffect(() => {
    if (itemSize === "small") {
      setItemSizeState("small");
    } else if (itemSize === "medium") {
      setItemSizeState("medium");
    } else if (itemSize === "large") {
      setItemSizeState("large");
    }
  }, [itemSize]);

  const onProjectClick = async () => {
    if (clickAdmin === true) {
      adminState.setActiveListing(listItem.id);
      adminState.setActiveListingName(listItem.name);
    }
    // Check if listing is included in exploreSortState.results
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listItem.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && listItem.searchId !== undefined) {
      console.log("clickedObjectIDsAfterSearch");
      try {
        await aa("clickedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Listing Clicked after Search",
          queryID: listItem.searchId,
          objectIDs: [listItem.id],
          positions: [indexKey + 1],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      console.log("clickedObjectIDs");
      try {
        await aa("clickedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Listing Clicked",
          objectIDs: [listItem.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  const onFollowClick = async () => {
    if (user?.uid === undefined) return;
    if (isFollowingLoading === true) return;
    setIsFollowingLoading(true);
    if (isFollowing === true) {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userBookmarks",
        user?.uid + "--" + listItem.id
      );
      try {
        await deleteDoc(followingRef);
        setIsFollowingLoading(false);
        snackbarStates.setMessage(listItem.name + " removed from bookmarks");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage(
          `Error removing ${listItem.name} from bookmarks, please try again`
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }

      // Send event to algolia
      let isFound = false;
      for (let i = 0; i < exploreSortState.results.length; i++) {
        if (exploreSortState.results[i].id === listItem.id) {
          isFound = true;
          break;
        }
      }
      if (isFound && listItem.searchId !== undefined) {
        console.log("convertedObjectIDsAfterSearch");
        try {
          await aa("convertedObjectIDsAfterSearch", {
            index: "gath3r_listings",
            eventName: "Bookmark removed from listing after Search",
            queryID: listItem.searchId,
            objectIDs: [listItem.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      } else {
        console.log("convertedObjectIDs");
        try {
          await aa("convertedObjectIDs", {
            index: "gath3r_listings",
            eventName: "Bookmark removed from listing",
            objectIDs: [listItem.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      }
    } else {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userBookmarks",
        user?.uid + "--" + listItem.id
      );
      try {
        await setDoc(followingRef, {
          following: listItem.id,
          follower: user?.uid,
          followedAt: Date.now(),
          name: listItem.name,
          category: listItem.category,
        });
        setIsFollowingLoading(false);
        snackbarStates.setMessage(listItem.name + " added to bookmarks");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage(
          `Error adding ${listItem.name} to bookmarks, please try again`
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
      // Send event to algolia
      let isFound = false;
      for (let i = 0; i < exploreSortState.results.length; i++) {
        if (exploreSortState.results[i].id === listItem.id) {
          isFound = true;
          break;
        }
      }
      if (isFound && listItem.searchId !== undefined) {
        console.log("convertedObjectIDsAfterSearch");
        try {
          await aa("convertedObjectIDsAfterSearch", {
            index: "gath3r_listings",
            eventName: "Listing Bookmarked after Search",
            queryID: listItem.searchId,
            objectIDs: [listItem.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      } else {
        console.log("convertedObjectIDs");
        try {
          await aa("convertedObjectIDs", {
            index: "gath3r_listings",
            eventName: "Listing Bookmarked",
            objectIDs: [listItem.id],
          });
        } catch (error) {
          console.error(error);
          console.log(error);
        }
      }
    }
  };

  // Check if user is following
  useEffect(() => {
    if (user?.uid === undefined) return;
    if (
      listItem.id === null ||
      listItem.id === undefined ||
      listItem.id === ""
    ) {
      return;
    }
    if (isFollowingLoading === true) return;
    setIsFollowingLoading(true);
    const followingRef = doc(
      firestoreDb,
      "follow",
      user?.uid,
      "userBookmarks",
      user?.uid + "--" + listItem.id
    );
    const unsubscribe = onSnapshot(followingRef, (doc) => {
      if (doc.exists()) {
        setIsFollowing(true);
        setIsFollowingLoading(false);
      } else {
        setIsFollowing(false);
        setIsFollowingLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user?.uid, listItem.id]);

  const onShareClick = async () => {
    copy(`https://app.gath3r.co/listings/page/${listItem.id}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);

    // Send event to algolia
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listItem.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && exploreSortState.results[0].searchId !== undefined) {
      console.log("convertedObjectIDsAfterSearch");
      try {
        await aa("convertedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Listing share click after Search",
          queryID: exploreSortState.results[0].searchId,
          objectIDs: [listItem.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      console.log("convertedObjectIDs");
      try {
        await aa("convertedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Listing share click",
          objectIDs: [listItem.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  const styles = {
    avatar: {
      bgcolor: globalTheme.boxBackgroundWhite.color,
      color: globalTheme.iconNeutral.color,
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "28px",
        height: "28px",
        minWidth: "28px",
        minHeight: "28px",
      },
      "@media (min-width: 600px)": {
        width: "20px",
        height: "20px",
        minWidth: "20px",
        minHeight: "20px",
      },
      "@media (min-width: 800px)": {
        width: "28px",
        height: "28px",
        minWidth: "28px",
        minHeight: "28px",
      },
    },
    avatarMedium: {
      bgcolor: globalTheme.boxBackgroundWhite.color,
      color: globalTheme.iconNeutral.color,
      width: "32px",
      height: "32px",
      minWidth: "32px",
      minHeight: "32px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
      "@media (min-width: 600px)": {
        width: "32px",
        height: "32px",
        minWidth: "32px",
        minHeight: "32px",
      },
      "@media (min-width: 800px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
    },
    avatarLarge: {
      bgcolor: globalTheme.boxBackgroundWhite.color,
      color: globalTheme.iconNeutral.color,
      width: "40px",
      height: "40px",
      minWidth: "40px",
      minHeight: "40px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "50px",
        height: "50px",
        minWidth: "50px",
        minHeight: "50px",
      },
      "@media (min-width: 600px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
      "@media (min-width: 800px)": {
        width: "50px",
        height: "50px",
        minWidth: "50px",
        minHeight: "50px",
      },
    },
    bookmarkIcon: {
      fontSize: "16px",
      padding: 0,
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "20px",
      },
      "@media (min-width: 600px)": {
        fontSize: "16px",
      },
      "@media (min-width: 800px)": {
        fontSize: "20px",
      },
    },
    shareIcon: {
      fontSize: "14px",
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "18px",
      },
      "@media (min-width: 600px)": {
        fontSize: "14px",
      },
      "@media (min-width: 800px)": {
        fontSize: "18px",
      },
    },
  };

  return itemSizeState === "small" ? (
    <div
      className="ProjectListItemSmall"
      style={{
        backgroundColor:
          listItem.rugged === true
            ? globalTheme.ruggedSurface.color
            : globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      <div className="ProjectListItemHeader">
        <Link
          to={clickAdmin === true ? "" : `/listings/page/${listItem.id}`}
          style={{ textDecoration: "none" }}
          className="ProjectListItemHeaderClickable"
          onClick={onProjectClick}
        >
          {listItem.logo !== "" &&
          listItem.logo !== undefined &&
          listItem.logo !== null ? (
            <div className="ProjectListItemAvatar">
              <LazyLoadImg imgUrl={listItem.logo} />
            </div>
          ) : (
            <Avatar sx={styles.avatar} alt={listItem.name}>
              {listItem.name.substr(0, 2)}
            </Avatar>
          )}
          <div className="ProjectListItemHeaderTextOuterDivMedium">
            <div className="ProjectListItemHeaderTextOuterDiv">
              <div className="ProjectListItemHeaderTitleOuter">
                <p
                  className="ProjectListItemHeaderTitle"
                  style={{
                    color: globalTheme.primaryText.color,
                  }}
                >
                  {listItem.name}
                </p>
                {listItem.manualVerified === true &&
                  listItem.rugged !== true && (
                    <Tooltip title="Verified by GATH3R">
                      <div className="ProjectListItemHeaderBadge">
                        <ProjectVerifiedBadge />
                      </div>
                    </Tooltip>
                  )}
                {listItem.rugged === true && (
                  <Tooltip title="Rugged">
                    <div className="ProjectListItemHeaderBadgeRugged">
                      <ProjectRuggedBadge />
                    </div>
                  </Tooltip>
                )}
              </div>
              <p
                className="ProjectListItemHeaderCategory"
                style={{
                  color:
                    listItem.rugged === true
                      ? globalTheme.iconNeutral.color
                      : globalTheme.links.color,
                }}
              >
                {listItem.category}
              </p>
            </div>
          </div>
        </Link>
        <div className="ProjectListItemHeaderIcons">
          {isFollowing ? (
            <Tooltip title="Remove from Bookmarks">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
                size="small"
                onClick={onFollowClick}
              >
                <BookmarkOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add to Bookmarks">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
                size="small"
                onClick={onFollowClick}
              >
                <BookmarkBorderOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Share">
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                marginLeft: "5px",
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon sx={styles.shareIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  ) : itemSizeState === "medium" ? (
    <div
      className="ProjectListItemMedium"
      style={{
        backgroundColor:
          listItem.rugged === true
            ? globalTheme.ruggedSurface.color
            : globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      <div className="ProjectListItemHeader">
        <Link
          to={clickAdmin === true ? "" : `/listings/page/${listItem.id}`}
          style={{ textDecoration: "none", padding: "15px 0px" }}
          className="ProjectListItemHeaderClickable"
          onClick={onProjectClick}
        >
          {listItem.logo !== "" &&
          listItem.logo !== undefined &&
          listItem.logo !== null ? (
            <div className="ProjectListItemAvatarMedium">
              <LazyLoadImg imgUrl={listItem.logo} />
            </div>
          ) : (
            <Avatar sx={styles.avatarMedium} alt={listItem.name}>
              {listItem.name.substr(0, 2)}
            </Avatar>
          )}
          <div className="ProjectListItemHeaderTextOuterDivMedium">
            <div className="ProjectListItemHeaderTextOuterDiv">
              <div className="ProjectListItemHeaderTitleOuter">
                <p
                  className="ProjectListItemHeaderTitle"
                  style={{
                    color: globalTheme.primaryText.color,
                  }}
                >
                  {listItem.name}
                </p>
                {listItem.manualVerified === true &&
                  listItem.rugged !== true && (
                    <Tooltip title="Verified by GATH3R">
                      <div className="ProjectListItemHeaderBadge">
                        <ProjectVerifiedBadge />
                      </div>
                    </Tooltip>
                  )}
                {listItem.rugged === true && (
                  <Tooltip title="Rugged">
                    <div className="ProjectListItemHeaderBadgeRugged">
                      <ProjectRuggedBadge />
                    </div>
                  </Tooltip>
                )}
              </div>
              <p
                className="ProjectListItemHeaderCategory"
                style={{
                  color:
                    listItem.rugged === true
                      ? globalTheme.iconNeutral.color
                      : globalTheme.links.color,
                }}
              >
                {listItem.category}
              </p>
            </div>
            <div
              className="ProjectListItemHeaderRatingOuter"
              style={{ marginLeft: "8px" }}
            >
              <ProjectItemRating listItem={listItem} />
            </div>
          </div>
        </Link>
        <div className="ProjectListItemHeaderIconsMedium">
          {isFollowing ? (
            <Tooltip title="Remove from Bookmarks">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginBottom: "3px",
                }}
                size="small"
                onClick={onFollowClick}
              >
                <BookmarkOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add to Bookmarks">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginBottom: "3px",
                }}
                size="small"
                onClick={onFollowClick}
              >
                <BookmarkBorderOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Share">
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                marginTop: "3px",
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon sx={styles.shareIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="ProjectListItemLarge"
      style={{
        backgroundColor:
          listItem.rugged === true
            ? globalTheme.ruggedSurface.color
            : globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      <div className="ProjectListItemHeader">
        <Link
          to={clickAdmin === true ? "" : `/listings/page/${listItem.id}`}
          style={{ textDecoration: "none" }}
          className="ProjectListItemHeaderClickable"
          onClick={onProjectClick}
        >
          {listItem.logo !== "" &&
          listItem.logo !== undefined &&
          listItem.logo !== null ? (
            <div className="ProjectListItemAvatarLarge">
              <LazyLoadImg imgUrl={listItem.logo} />
            </div>
          ) : (
            <Avatar sx={styles.avatarLarge} alt={listItem.name}>
              {listItem.name.substr(0, 2)}
            </Avatar>
          )}
          <div className="ProjectListItemHeaderTextOuterDivMedium">
            <div className="ProjectListItemHeaderTextOuterDiv">
              <div className="ProjectListItemHeaderTitleOuter">
                <p
                  className="ProjectListItemHeaderTitleLarge"
                  style={{
                    color: globalTheme.primaryText.color,
                  }}
                >
                  {listItem.name}
                </p>
                {listItem.manualVerified === true &&
                  listItem.rugged !== true && (
                    <Tooltip title="Verified by GATH3R">
                      <div className="ProjectListItemHeaderBadgeLarge">
                        <ProjectVerifiedBadge />
                      </div>
                    </Tooltip>
                  )}
                {listItem.rugged === true && (
                  <Tooltip title="Rugged">
                    <div className="ProjectListItemHeaderBadgeRuggedLarge">
                      <ProjectRuggedBadge />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Link>
        <div className="ProjectListItemHeaderIconsMedium">
          {isFollowing ? (
            <Tooltip title="Remove from Bookmarks">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginBottom: "3px",
                }}
                size="small"
                onClick={onFollowClick}
              >
                <BookmarkOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add to Bookmarks">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginBottom: "3px",
                }}
                size="small"
                onClick={onFollowClick}
              >
                <BookmarkBorderOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Share">
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                marginTop: "3px",
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon sx={styles.shareIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Link
        to={clickAdmin === true ? "" : `/listings/page/${listItem.id}`}
        style={{ textDecoration: "none" }}
        className="ProjectListItemInnerLarge"
        onClick={onProjectClick}
      >
        <div className="ProjectListItemHeaderRatingOuterLarge">
          <ProjectItemRating listItem={listItem} />
        </div>
        <p
          className="ProjectListItemInnerLargeText"
          style={{
            color: globalTheme.primaryText.color,
          }}
        >
          {listItem.description}
        </p>
        <p
          className="ProjectListItemFooterText"
          style={{
            color:
              listItem.rugged === true
                ? globalTheme.iconNeutral.color
                : globalTheme.links.color,
          }}
        >
          {listItem.category}
        </p>
      </Link>
    </div>
  );
}
