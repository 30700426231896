export const categoryEnums = [
    {
        displayname: 'Accelerator',
        value: 'accelerator'
    },
     {
        displayname: 'Agency',
        value: 'agency'
    },
    {
        displayname: 'Aggregator Platform',
        value: 'aggregator-platform'
    },
    {
        displayname: 'Artificial Intelligence (AI)',
        value: 'ai'
    },
     {
        displayname: 'Auditor',
        value: 'auditor'
    },
    {
        displayname: 'Blockchain',
        value: 'blockchain'
    },
    {
        displayname: 'Blockchain Accounting Services',
        value: 'blockchain-accounting-services'
    },
    {
        displayname: 'Blockchain Development Company',
        value: 'blockchain-development-company'
    },
    {
        displayname: 'Bot',
        value: 'bot'
    },
    {
        displayname: 'Bridges',
        value: 'bridges'
    },
    {
        displayname: 'Centralized Exchanges (CEX)',
        value: 'cex'
    },
    {
        displayname: 'Coalition',
        value: 'coalition'
    },
    {
        displayname: 'Community',
        value: 'community'
    },
    {
        displayname: 'Community VC',
        value: 'community-vc'
    },
    {
        displayname: 'Crypto Legal Support',
        value: 'crypto-legal-support'
    },
    {
        displayname: 'DApps',
        value: 'dapps'
    },
    {
        displayname: 'DAO',
        value: 'dao'
    },
    {
        displayname: 'Decentralized Exchanges (DEX)',
        value: 'dex'
    },
    {
        displayname: 'Defi',
        value: 'defi'
    },
    {
        displayname: 'Derivatives',
        value: 'derivatives'
    },
    {
        displayname: 'Developers Hub',
        value: 'developers-hub'
    },
    {
        displayname: 'Domain',
        value: 'domain'
    },
    {
        displayname: 'Ecosystem',
        value: 'ecosystem'
    },
    {
        displayname: 'Event',
        value: 'event'
    },
    {
        displayname: 'Event Organizer',
        value: 'event-organizer'
    },
    {
        displayname: 'Exchange',
        value: 'exchange'
    },
    {
        displayname: 'Game',
        value: 'game'
    },
    {
        displayname: 'Gaming Platform',
        value: 'gaming-platform'
    },
     {
        displayname: 'Grant',
        value: 'grant'
    },
     {
        displayname: 'Incubator',
        value: 'incubator'
    },
     {
        displayname: 'Infrastructure',
        value: 'infrastructure'
    },
    {
        displayname: 'Internet of Things (IoT)',
        value: 'iot'
    },
    {
        displayname: 'Launchpads',
        value: 'launchpads'
    },
    {
        displayname: 'Lending/Borrowing ',
        value: 'lending-borrowing'
    },
    {
        displayname: 'Market Maker',
        value: 'market-maker'
    },
    {
        displayname: 'Media',
        value: 'media'
    },
    {
        displayname: 'Meme',
        value: 'meme'
    },
    {
        displayname: 'Messenger',
        value: 'messenger'
    },
    {
        displayname: 'Metaverse',
        value: 'metaverse'
    },
    {
        displayname: 'Move to Earn',
        value: 'move-to-earn'
    },
    {
        displayname: 'NFT',
        value: 'nft'
    },
    {
        displayname: 'NFT Marketplaces',
        value: 'nft-marketplaces'
    },
    {
        displayname: 'Node',
        value: 'node'
    },
    {
        displayname: 'Oracle',
        value: 'oracle'
    },
    {
        displayname: 'Payment',
        value: 'payment'
    },
    {
        displayname: 'Protocol',
        value: 'protocol'
    },
    {
        displayname: 'Quest Platform',
        value: 'quest-platform'
    },
    {
        displayname: 'Real World Assets (RWA)',
        value: 'rwa'
    },
    {
        displayname: 'ReFi',
        value: 'refi'
    },
    {
        displayname: 'Social',
        value: 'social'
    },
    {
        displayname: 'SocialFi',
        value: 'socialfi'
    },
    {
        displayname: ' Social Graph',
        value: 'social-graph'
    },
    {
        displayname: 'Stable Coins',
        value: 'stable-coins'
    },
    {
        displayname: 'Storage',
        value: 'storage'
    },
    {
        displayname: 'Token',
        value: 'token'
    },
    {
        displayname: 'Tools',
        value: 'tools'
    },
    {
        displayname: 'VC',
        value: 'vc'
    },
    {
        displayname: 'Wallet',
        value: 'wallet'
    },
]

export const pageEnums = [
  {
    value: "explore",
    label: "Explore",
    icon: 'explore',
    path: "/explore",
    uidRequired: false,
  },
  {
    value: "reviews",
    label: "Reviews",
    icon: 'reviews',
    path: "/reviews",
    uidRequired: false,
  },
  {
    value: "users",
    label: "Users",
    icon: 'group',
    path: "/users",
    uidRequired: false,
  },
  {
    value: "wallets",
    label: "Wallets",
    icon: 'account_balance_wallet',
    path: "/wallets",
    uidRequired: false,
  },
  {
    value: "profile",
    label: "Profile",
    icon: 'account_circle',
    path: "/profile",
    uidRequired: false,
  },
  {
    value: "bookmarks",
    label: "Bookmarks",
    icon: 'book',
    path: "/profile/bookmarks",
    uidRequired: false,
  },
  {
    value: "achievements",
    label: "Achievements",
    icon: 'editor_choice',
    path: "/achievements",
    uidRequired: false,
  },
  {
    value: "my-points",
    label: "Points",
    icon: "score",
    path: "/user/points",
    uidRequired: true,
  },
  {
    value: "my-listings",
    label: "My Listings",
    icon: 'list_alt',
    path: "/user/listings",
    uidRequired: true,
  },
  {
    value: "my-reviews",
    label: "My Reviews",
    icon: 'rate_review',
    path: "/reviews/user",
    uidRequired: true,
  },
  {
    value: "my-ratings",
    label: "My Ratings",
    icon: 'stars',
    path: "/reviews/user/rating",
    uidRequired: true,
  },
  {
    value: "my-followers",
    label: "My Followers",
    icon: 'group_add',
    path: "/follow/in",
    uidRequired: true,
  },
  {
    value: "my-following",
    label: "Following",
    icon: 'person_add',
    path: "/follow/out",
    uidRequired: true,
  },
  {
    value: "settings",
    label: "Settings",
    icon: 'explore',
    path: "/profile/settings",
    uidRequired: false,
  },
];

export const pfpEnums = {
    background: {
        low: 1,
        high: 12,
    },
    bodyBoy: {
        low: 1,
        high: 14,
    },
    bodyGirl: {
        low: 11,
        high: 20,
    },
    faceBoy: {
        low: 11,
        high: 24,
    },
    faceGirl: {
        low: 11,
        high: 20,
    },
    headBoy: {
        low: 1,
        high: 19,
    },
    headGirl: {
        low: 1,
        high: 21,
    },
}