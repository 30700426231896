import React, { useEffect } from "react";
import aa from 'search-insights';
import {
  useAuthStates,
  useSettingsStates,
} from "../utils/globalStates";
import { ref, onValue } from "firebase/database";
import { firebaseRlDb } from "../firebase/database";
import { initialDashSettings } from "../../views/dashboard/dashEnums";
import { getUserRole, firebaseAuth } from "../firebase/auth";

import { doc, onSnapshot } from "firebase/firestore";
import { firestoreDb } from "../firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

let isLoadingRoles = false;
let isLoadingSettings = false;

export default function FirebaseListeners() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const authStates = useAuthStates((state) => state);
  const settingsStates = useSettingsStates((state) => state);

  useEffect(() => {
    if (isLoadingRoles) return;
    isLoadingRoles = true;
    if (user !== null) {
      getUserRole().then((role: any) => {
        authStates.setRole(role);
      });
    }
    isLoadingRoles = false;
  }, [user]);

  useEffect(() => {
    if (user === null || user === undefined) return;
    // @ts-ignore
    aa("setAuthenticatedUserToken", user.uid);
  }, [user]);

  useEffect(() => {
    if (user === null) return;
    isLoadingSettings = true;
    const dbRef = ref(firebaseRlDb, `public/settings/user/${user?.uid}`);
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data.tab !== null && data.tab !== undefined && data.tab !== "") {
          settingsStates.setTab(data.tab.split(","));
        } else {
          settingsStates.setTab('/explore,/users,/profile,/profile/settings'.split(","));
        }
        if (data.dashboard !== null && data.dashboard !== undefined) {
          settingsStates.setDashboard(Object.values(data.dashboard));
          settingsStates.setDashboardInitialLoaded(true);
        } else {
          settingsStates.setDashboard(initialDashSettings);
          settingsStates.setDashboardInitialLoaded(true);
        }
      } else {
        console.log("No data available");
        settingsStates.setTab('/explore,/users,/profile,/profile/settings'.split(","));
        settingsStates.setDashboard(initialDashSettings);
        settingsStates.setDashboardInitialLoaded(true);
        
      }
      isLoadingSettings = false;
    });

    return () => unsubscribe();
  }, [user]);


  // Listen for updates on userPrivateInfo and update global state
  useEffect(() => {
    if (user === null || user === undefined) return;
    const userRef = doc(firestoreDb, "privateUserInfo", user.uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        authStates.setUserData(data);
        authStates.setUserDataLoaded(true);
      } else {
        console.log("No such document!");
        authStates.setUserData(null);
        authStates.setUserDataLoaded(true);
      }
    });

    return () => unsubscribe();
  }, [user]);
  
  return <></>;
}
