import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import moment from "moment";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit,
} from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";

import AddReviewItem from "../../components/AddReviewItem";
import RatingListItem from "../../../reviews/components/RatingListItem";
import ReviewItem from "../../../reviews/components/ReviewItem";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./ListingPageReviewPreviews.css";
import { ButtonBase, CircularProgress } from "@mui/material";

moment().format();

export default function ListingPageReviewPreviews({
  listing,
}: {
  listing: any;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState<any[]>([]);

  useEffect(() => {
    if (listing.id === null || listing.id === undefined) {
      return;
    }
    setIsLoading(true);
    const reviewsQ = query(
          collection(firestoreDb, "reviews"),
          where("listing", "==", listing.id),
          limit(10)
        );
    const unsubscribe = onSnapshot(
      reviewsQ,
      (snapshot) => {
        const reviewsData: any[] = [];
        snapshot.forEach((doc) => {
          reviewsData.push(doc.data());
        });
        setReviews(reviewsData);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => unsubscribe();
  }, [listing.id]);
  return (
    <div className="ListingPageReviewPreviews">
      <AddReviewItem listing={listing} />
      {isLoading ? (
         <div className="ListingPageReviewPreviewsEmpty">
        <CircularProgress
          style={{
            color: globalTheme.backgroundMainOffBeige.color,
            marginTop: "20px",
          }}
        />
      </div>
      ) : reviews.length > 0 ? (
        <div className="ListingPageReviewInner">
          {reviews.map((reviewItem) => {
            if (reviewItem.review === true) {
            return (
              <ReviewItem key={reviewItem.id} reviewItem={reviewItem} isListing={true} isUser={false} />
            );
            } else {
              return (
                <RatingListItem key={reviewItem.id} reviewItem={reviewItem} isListing={true} isUser={false} />
              );
            }
          })}
          {reviews.length > 4 && (
            <ButtonBase style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Link
                to={`/reviews/listing/${listing.id}`}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <RateReviewOutlinedIcon
                  sx={{
                    color: globalTheme.backgroundMainOffBeige.color,
                    fontSize: "12px",
                    marginRight: "3px",
                    marginTop: "2px",
                    "@media (min-width: 350px)": {
                      fontSize: "13px",
                    },
                    "@media (min-width: 550px)": {
                      fontSize: "14px",
                    },
                    "@media (min-width: 700px)": {
                      fontSize: "15px",
                    },
                  }}
                />
                <p
                  className="ListingPageReviewInnerLink"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  See all reviews
                </p>
              </Link>
            </ButtonBase>
          )}
        </div>
      ) : (
        <div className="ListingPageReviewPreviewsEmpty">
          <p
            className="ListingPageReviewError"
            style={{ color: globalTheme.backgroundMainOffBeige.color }}
          >
            No reviews yet
          </p>
          <ButtonBase style={{ marginTop: "10px" }}>
            <RateReviewOutlinedIcon
              sx={{
                color: globalTheme.backgroundMainOffBeige.color,
                fontSize: "12px",
                marginRight: "3px",
                marginTop: "2px",
                "@media (min-width: 350px)": {
                  fontSize: "13px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "14px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "15px",
                },
              }}
            />
            <p
              className="ListingPageReviewInnerLink"
              style={{ color: globalTheme.backgroundMainOffBeige.color }}
            >
              Write a review
            </p>
          </ButtonBase>
        </div>
      )}
    </div>
  );
}
