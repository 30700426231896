import React, { useEffect, useState } from "react";
import {
  query,
  orderBy,
  limit,
  collection,
  onSnapshot,
  OrderByDirection,
  where,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";
import { firestoreDb } from "../../components/firebase/firestore";
import { useThemeState } from "../../components/utils/globalStates";
import AdminListingDialog from "./dialogs/AdminListingDialog";
import AdminUserDialog from "./dialogs/AdminUserDialog";
import { standardDialogPaper, standardDialogRoot } from "../../components/theme/globalMuiTheme";
import "./Admin.css";

moment().format();

export default function AdminReports() {
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [listingNewVisible, setListingNewVisible] = useState(true);
  const [listingNewArray, setListingNewArray] = useState<any[]>([]);

  const [listingHandledVisible, setListingHandledVisible] = useState(false);
  const [listingHandledArray, setListingHandledArray] = useState<any[]>([]);

  const [listingClosedVisible, setListingClosedVisible] = useState(false);
  const [listingClosedArray, setListingClosedArray] = useState<any[]>([]);

  const [listingMergedVisible, setListingMergedVisible] = useState(false);
  const [listingMergedArray, setListingMergedArray] = useState<any[]>([]);

  const [reviewNewVisible, setReviewNewVisible] = useState(false);
  const [reviewNewArray, setReviewNewArray] = useState<any[]>([]);

  const [reviewHandledVisible, setReviewHandledVisible] = useState(false);
  const [reviewHandledArray, setReviewHandledArray] = useState<any[]>([]);

  const [reviewClosedVisible, setReviewClosedVisible] = useState(false);
  const [reviewClosedArray, setReviewClosedArray] = useState<any[]>([]);

  const [userNewVisible, setUserNewVisible] = useState(false);
  const [userNewArray, setUserNewArray] = useState<any[]>([]);

  const [userHandledVisible, setUserHandledVisible] = useState(false);
  const [userHandledArray, setUserHandledArray] = useState<any[]>([]);

  const [userClosedVisible, setUserClosedVisible] = useState(false);
  const [userClosedArray, setUserClosedArray] = useState<any[]>([]);

  const [mergeNewVisible, setMergeNewVisible] = useState(false);
  const [mergeNewArray, setMergeNewArray] = useState<any[]>([]);

  const [mergeHandledVisible, setMergeHandledVisible] = useState(false);
  const [mergeHandledArray, setMergeHandledArray] = useState<any[]>([]);

  const [mergeClosedVisible, setMergeClosedVisible] = useState(false);
  const [mergeClosedArray, setMergeClosedArray] = useState<any[]>([]);

  const [collectedArray, setCollectedArray] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [listingDialogOpen, setListingDialogOpen] = useState(false);
  const [listingId, setListingId] = useState("");
  const [listingName, setListingName] = useState("");
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [userId, setUserId] = useState("");

  // Fetch reports with new status from listingReports collection if listingNewVisible is true
  useEffect(() => {
    if (listingNewVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "listingReports"),
        where("status", "==", "new"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const newReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          newReports.push({ ...doc.data(), type: "Listing" });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = newReports.map(async (report) => {
          if (
            report.reporterName === null ||
            report.reporterName === undefined ||
            report.reporterName === ""
          ) {
            const reporterRef = doc(firestoreDb, "privateUserInfo", report.uid);
            const reporterSnap = await getDoc(reporterRef);
            const reporterData = reporterSnap.data();
            report.reporterName = reporterData?.displayName;
          }
          if (
            report.name === null ||
            report.name === undefined ||
            report.name === ""
          ) {
            const listingRef = doc(firestoreDb, "listings", report.userId);
            const listingSnap = await getDoc(listingRef);
            const listingData = listingSnap.data();
            report.name = listingData?.name;
          }
        });
        await Promise.all(promises);
        setListingNewArray(newReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setListingNewArray([]);
    }
  }, [listingNewVisible]);

  // Fetch reports with handled status from listingReports collection if listingHandledVisible is true
  useEffect(() => {
    if (listingHandledVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "listingReports"),
        where("status", "==", "handled"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const handledReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          handledReports.push({ ...doc.data(), type: "Listing" });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = handledReports.map(async (report) => {
          if (
            report.reporterName === null ||
            report.reporterName === undefined ||
            report.reporterName === ""
          ) {
            const reporterRef = doc(firestoreDb, "privateUserInfo", report.uid);
            const reporterSnap = await getDoc(reporterRef);
            const reporterData = reporterSnap.data();
            report.reporterName = reporterData?.displayName;
          }
          if (
            report.name === null ||
            report.name === undefined ||
            report.name === ""
          ) {
            const listingRef = doc(firestoreDb, "listings", report.userId);
            const listingSnap = await getDoc(listingRef);
            const listingData = listingSnap.data();
            report.name = listingData?.name;
          }
        });
        await Promise.all(promises);
        setListingHandledArray(handledReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setListingHandledArray([]);
    }
  }, [listingHandledVisible]);

  // Fetch reports with closed status from listingReports collection if listingClosedVisible is true
  useEffect(() => {
    if (listingClosedVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "listingReports"),
        where("status", "==", "closed"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const closedReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          closedReports.push({ ...doc.data(), type: "Listing" });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = closedReports.map(async (report) => {
          if (
            report.reporterName === null ||
            report.reporterName === undefined ||
            report.reporterName === ""
          ) {
            const reporterRef = doc(firestoreDb, "privateUserInfo", report.uid);
            const reporterSnap = await getDoc(reporterRef);
            const reporterData = reporterSnap.data();
            report.reporterName = reporterData?.displayName;
          }
          if (
            report.name === null ||
            report.name === undefined ||
            report.name === ""
          ) {
            const listingRef = doc(firestoreDb, "listings", report.userId);
            const listingSnap = await getDoc(listingRef);
            const listingData = listingSnap.data();
            report.name = listingData?.name;
          }
        });
        await Promise.all(promises);
        setListingClosedArray(closedReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setListingClosedArray([]);
    }
  }, [listingClosedVisible]);

  // Fetch reports with new status from userReports collection if userNewVisible is true
  useEffect(() => {
    if (userNewVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "userReports"),
        where("status", "==", "new"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const newReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          newReports.push({ ...doc.data(), type: "User" });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = newReports.map(async (report) => {
          if (
            report.reporterName === null ||
            report.reporterName === undefined ||
            report.reporterName === ""
          ) {
            const reporterRef = doc(firestoreDb, "privateUserInfo", report.uid);
            const reporterSnap = await getDoc(reporterRef);
            const reporterData = reporterSnap.data();
            report.reporterName = reporterData?.displayName;
          }
          if (
            report.name === null ||
            report.name === undefined ||
            report.name === ""
          ) {
            const userRef = doc(firestoreDb, "privateUserInfo", report.userId);
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data();
            report.name = userData?.displayName;
          }
        });
        await Promise.all(promises);
        setUserNewArray(newReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setUserNewArray([]);
    }
  }, [userNewVisible]);

  // Fetch reports with handled status from userReports collection if userHandledVisible is true
  useEffect(() => {
    if (userHandledVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "userReports"),
        where("status", "==", "handled"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const handledReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          handledReports.push({ ...doc.data(), type: "User" });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = handledReports.map(async (report) => {
          if (
            report.reporterName === null ||
            report.reporterName === undefined ||
            report.reporterName === ""
          ) {
            const reporterRef = doc(firestoreDb, "privateUserInfo", report.uid);
            const reporterSnap = await getDoc(reporterRef);
            const reporterData = reporterSnap.data();
            report.reporterName = reporterData?.displayName;
          }
          if (
            report.name === null ||
            report.name === undefined ||
            report.name === ""
          ) {
            const userRef = doc(firestoreDb, "privateUserInfo", report.userId);
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data();
            report.name = userData?.displayName;
          }
        });
        await Promise.all(promises);
        setUserHandledArray(handledReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setUserHandledArray([]);
    }
  }, [userHandledVisible]);

  // Fetch reports with closed status from userReports collection if userClosedVisible is true
  useEffect(() => {
    if (userClosedVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "userReports"),
        where("status", "==", "closed"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const closedReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          closedReports.push({ ...doc.data(), type: "User" });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = closedReports.map(async (report) => {
          if (
            report.reporterName === null ||
            report.reporterName === undefined ||
            report.reporterName === ""
          ) {
            const reporterRef = doc(firestoreDb, "privateUserInfo", report.uid);
            const reporterSnap = await getDoc(reporterRef);
            const reporterData = reporterSnap.data();
            report.reporterName = reporterData?.displayName;
          }
          if (
            report.name === null ||
            report.name === undefined ||
            report.name === ""
          ) {
            const userRef = doc(firestoreDb, "privateUserInfo", report.userId);
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data();
            report.name = userData?.displayName;
          }
        });
        await Promise.all(promises);
        setUserClosedArray(closedReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setUserClosedArray([]);
    }
  }, [userClosedVisible]);

  // Listen for changes in all arrays and update collectedArray
  useEffect(() => {
    const allArrays = [
      ...listingNewArray,
      ...listingHandledArray,
      ...listingClosedArray,
      ...reviewNewArray,
      ...reviewHandledArray,
      ...reviewClosedArray,
      ...userNewArray,
      ...userHandledArray,
      ...userClosedArray,
      ...listingMergedArray,
    ];
    const allArraysFlat = allArrays.flat();
    console.log("All arrays");
    console.log(allArraysFlat);
    setCollectedArray(allArraysFlat);
  }, [
    listingNewArray,
    listingHandledArray,
    listingClosedArray,
    reviewNewArray,
    reviewHandledArray,
    reviewClosedArray,
    userNewArray,
    userHandledArray,
    userClosedArray,
    listingMergedArray,
  ]);

  // Function to update status of a report
  const handleStatusChange = async (
    e: SelectChangeEvent,
    reportId: string,
    reportType: string
  ) => {
    const status = e.target.value;
    if (reportType === "Listing") {
      const reportRef = doc(firestoreDb, "listingReports", reportId);
      await updateDoc(reportRef, { status: status });
    } else if (reportType === "User") {
      const reportRef = doc(firestoreDb, "userReports", reportId);
      await updateDoc(reportRef, { status: status });
    }
  };

  const onOpenListingDialog = (listingId: string, listingName: string) => {
    if (listingId === "") {
      console.error("Listing ID is missing");
      return;
    }
    setListingId(listingId);
    setListingName(listingName);
    setListingDialogOpen(true);
  };

  const onOpenUserDialog = (userId: string) => {
    if (userId === "") {
      console.error("User ID is missing");
      return;
    }
    setUserId(userId);
    setUserDialogOpen(true);
  };
  const styles = {
    checkBox: {
      "&.Mui-checked": {
        color: globalTheme.solidMainBlack.color,
      },
      color: globalTheme.solidMainBlack.color,
    },
    formControl: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "center",
      marginLeft: "3px",
      marginRight: "3px",
      "& .MuiTypography-root": {
        fontSize: "11px !important",
        "@media (min-width: 350px)": {
          fontSize: "12px !important",
        },
        "@media (min-width: 550px)": {
          fontSize: "13px !important",
        },
        "@media (min-width: 700px)": {
          fontSize: "14px !important",
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },
    selectMenu: {
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        "& .MuiMenuItem-root": {
          opacity: 0.6,
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeige.color,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeige.color,
          backgroundColor: globalTheme.backgroundMainBeige.color,
          opacity: 1,
        },
      },
    },
    input: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.backgroundMainOffBeige.color,
        },
      },
    },
  };

  return (
    <div className="AdminInner">
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        STILL IN PROGRESS
      </p>
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        Filter and sort reports and change status. <b>New</b> is for reports
        that have not been handled yet, <b>Handled</b> is for reports that have
        been handled with action taken and <b>Closed</b> is for reports that
        have been handled wihout any action taken.
      </p>
      <div className="AdminReportsHeader">
        <div className="AdminReportsHeaderInner">
          <p className="AdminReportsHeaderInnerTitle">Listings</p>
          <div className="AdminReportsHeaderFilter">
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={listingNewVisible}
                  onChange={(e) => setListingNewVisible(e.target.checked)}
                  inputProps={{ "aria-label": "New listings" }}
                />
              }
              label="New"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={listingHandledVisible}
                  onChange={(e) => setListingHandledVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Handled listings" }}
                />
              }
              label="Handled"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={listingClosedVisible}
                  onChange={(e) => setListingClosedVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Closed listings" }}
                />
              }
              label="Closed"
            />
          </div>
        </div>
        <div className="AdminReportsHeaderInner">
          <p className="AdminReportsHeaderInnerTitle">Users</p>
          <div className="AdminReportsHeaderFilter">
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={userNewVisible}
                  onChange={(e) => setUserNewVisible(e.target.checked)}
                  inputProps={{ "aria-label": "New listings" }}
                />
              }
              label="New"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={userHandledVisible}
                  onChange={(e) => setUserHandledVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Handled listings" }}
                />
              }
              label="Handled"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={userClosedVisible}
                  onChange={(e) => setUserClosedVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Closed listings" }}
                />
              }
              label="Closed"
            />
          </div>
        </div>
        <div className="AdminReportsHeaderInner">
          <p className="AdminReportsHeaderInnerTitle">Reviews</p>
          <div className="AdminReportsHeaderFilter">
            <FormControlLabel
              sx={styles.formControl}
              disabled
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={reviewNewVisible}
                  onChange={(e) => setReviewNewVisible(e.target.checked)}
                  inputProps={{ "aria-label": "New listings" }}
                />
              }
              label="New"
            />
            <FormControlLabel
              sx={styles.formControl}
              disabled
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={reviewHandledVisible}
                  onChange={(e) => setReviewHandledVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Handled listings" }}
                />
              }
              label="Handled"
            />
            <FormControlLabel
              sx={styles.formControl}
              disabled
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={reviewClosedVisible}
                  onChange={(e) => setReviewClosedVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Closed listings" }}
                />
              }
              label="Closed"
            />
          </div>
        </div>
        <div className="AdminReportsHeaderInner">
          <p className="AdminReportsHeaderInnerTitle">Merges</p>
          <div className="AdminReportsHeaderFilter">
            <FormControlLabel
              sx={styles.formControl}
              disabled
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={mergeNewVisible}
                  onChange={(e) => setMergeNewVisible(e.target.checked)}
                />
              }
              label="New"
            />
            <FormControlLabel
              sx={styles.formControl}
              disabled
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={mergeHandledVisible}
                  onChange={(e) => setMergeHandledVisible(e.target.checked)}
                />
              }
              label="Handled"
            />
            <FormControlLabel
              sx={styles.formControl}
              disabled
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={mergeClosedVisible}
                  onChange={(e) => setMergeClosedVisible(e.target.checked)}
                />
              }
              label="Closed"
            />
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="Reports table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
              <TableCell>Reporter</TableCell>
              <TableCell align="right">Reasons</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collectedArray.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.type}
                </TableCell>
                <TableCell>
                  <p
                    onClick={() => {
                      if (row.type === "Listing") {
                        onOpenListingDialog(row.userId, row.name);
                      } else if (row.type === "User") {
                        onOpenUserDialog(row.userId);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {row.name}
                  </p>
                </TableCell>
                <TableCell>
                  <OpenInNewOutlinedIcon
                    onClick={() => {
                      if (row.type === "Listing") {
                        window.open(
                          "https://app.gath3r.co/listings/page/" + row.userId,
                          "_blank"
                        );
                      } else if (row.type === "User") {
                        window.open(
                          "https://app.gath3r.co/users/page/" + row.userId,
                          "_blank"
                        );
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      color: globalTheme.solidMainBlack.color,
                      fontSize: "15px",
                      marginBottom: "-3px",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <p
                    onClick={() => {
                      window.open(
                        "https://app.gath3r.co/users/page/" + row.uid,
                        "_blank"
                      );
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {row.reporterName}
                  </p>
                </TableCell>
                <TableCell align="right">{row.reason}</TableCell>
                <TableCell align="right">
                  {moment(row.createdAt).format("LLL")}
                </TableCell>
                <TableCell align="right">
                  <FormControl fullWidth sx={{ ...styles.input }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={row.status}
                      label="Status"
                      size="small"
                      sx={{ ...styles.select }}
                      MenuProps={{
                        sx: styles.selectMenu,
                      }}
                      onChange={(e) => handleStatusChange(e, row.id, row.type)}
                    >
                      <MenuItem value={"new"}>New</MenuItem>
                      <MenuItem value={"handled"}>Handled</MenuItem>
                      <MenuItem value={"closed"}>Closed</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && (
        <div
          style={{
            ...globalTheme.backgroundMainOffBeige,
            marginTop: "30px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>Loading...</p>
          <CircularProgress
            size={15}
            color="inherit"
            sx={{ marginLeft: "10px" }}
          />
        </div>
      )}
      <Dialog
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          },
        }}
        onClose={() => setListingDialogOpen(false)}
        open={listingDialogOpen}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={() => setListingDialogOpen(false)}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent
            sx={{
              marginTop: "0px",
              paddingTop: "0px",
              paddingBottom: "50px",
            }}
          >
            <AdminListingDialog lastUpdatedAt={0} listingId={listingId} listingName={listingName} />
          </DialogContent>
      </Dialog>
      <Dialog
        sx={{
          zIndex: 9997,
          "& .MuiPaper-root": {
            width: "84vw",
            maxWidth: "600px",
            height: "80vh",
            backgroundColor: globalTheme.backgroundMainBeige.color,
          },
        }}
        onClose={() => setUserDialogOpen(false)}
        open={userDialogOpen}
      >
        <AdminUserDialog userId={userId} />
      </Dialog>
    </div>
  );
}
