import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { doc, onSnapshot, setDoc, deleteDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import copy from "copy-to-clipboard";
import { Helmet } from "react-helmet-async";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import ButtonBase from "@mui/material/ButtonBase";

import ReportIcon from "@mui/icons-material/Report";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useHeaderSettingsState,
  useTabSettingsState,
  useDeviceState,
  useThemeState,
  useAuthStates,
} from "../../../components/utils/globalStates";

import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../components/firebase/analytics";

import { PfpViewer } from "../../../components/ui/img/PfpViewer";
import UserPageCount from "./components/UserPageCount";
import ProfileAchievementsSlider from "../../profile/start/components/ProfileAchievementsSlider";
import ProfileReviewPreview from "../../profile/start/components/ProfileReviewsPreview";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { standardDialogPaper, standardDialogRoot, standardDialogTitle } from "../../../components/theme/globalMuiTheme";
import "./UserPage.css";
import "../../profile/start/Profile.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function UserPage() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userId } = useParams();
  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState();
  const snackbarStates = useSnackbarState();
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [userData, setUserData] = useState<any>({});
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);

  const [userReportDialog, setUserReportDialog] = useState(false);
  const [reasonString, setReasonString] = useState("");
  const [reasonArray, setReasonArray] = useState<string[]>([]);
  const [isSendingUserReport, setIsSendingUserReport] = useState(false);

  const onShareClick = () => {
    /*logEvent(firebaseAnalytics, "user_share", {
      user_id: user?.uid,
    });*/
    copy(`https://app.gath3r.co/users/page/${userId}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setReasonArray([...reasonArray, event.target.value]);
    } else {
      setReasonArray(
        reasonArray.filter((reason) => reason !== event.target.value)
      );
    }
  };

  // Update reasonString based on reasonArray
  useEffect(() => {
    setReasonString(reasonArray.join(","));
  }, [reasonArray]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle user report submit
  const onUserReportSubmit = async () => {
    if (isSendingUserReport) return;
    if (user?.uid === undefined || user?.uid === null) return;
    if (authStates.userData === null || authStates.userData === undefined) return;
    setIsSendingUserReport(true);
    if (reasonString === "") {
      snackbarError.setMessage("Reason is required");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setIsSendingUserReport(false);
      return;
    }
    const callable = httpsCallable(firebaseFunctions, "user-new_report");
    try {
      await callable({
        reason: reasonString,
        id: userId,
        token: await user?.getIdToken(),
        reporterName: authStates.userData.displayName,
        name: userData.displayName,
      });
      setUserReportDialog(false);
      setIsSendingUserReport(false);
      snackbarStates.setMessage(
        userData.displayName === ""
          ? "Anonymous user"
          : userData.displayName + " reported"
      );
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (e) {
      console.error(e);
      setIsSendingUserReport(false);
      snackbarError.setMessage("Error reporting user, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  // Set following or unfollowing
  const onFollowClick = async () => {
    if (user?.uid === undefined) return;
    if (isFollowingLoading === true) return;
    setIsFollowingLoading(true);
    if (isFollowing === true) {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userFollows",
        user?.uid + "-" + userId
      );
      try {
        await deleteDoc(followingRef);
        setIsFollowingLoading(false);
        snackbarStates.setMessage("Unfollowed");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage("Error unfollowing, please try again");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    } else {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userFollows",
        user?.uid + "-" + userId
      );
      try {
        await setDoc(followingRef, {
          following: userId,
          follower: user?.uid,
          followedAt: Date.now(),
        });
        setIsFollowingLoading(false);
        snackbarStates.setMessage("Followed");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage("Error following, please try again");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    }
  };

  // Fetch public user data from firestore
  useEffect(() => {
    if (userId === undefined) {
      snackbarError.setMessage("User not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/users");
    }
    const userRef = doc(firestoreDb, "publicUserInfo", userId);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setUserData(doc.data());
        setIsLoading(false);
      } else {
        snackbarError.setMessage("User not found");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return navigate("/users");
      }
    });
    return () => {
      unsubscribe();
    };
  }, [userId]);

  // Check if user is following
  useEffect(() => {
    if (user?.uid === undefined) return;
    const followingRef = doc(
      firestoreDb,
      "follow",
      user?.uid,
      "userFollows",
      user?.uid + "-" + userId
    );
    const unsubscribe = onSnapshot(followingRef, (doc) => {
      if (doc.exists()) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [userId, user?.uid]);

  useEffect(() => {
    headerSettingsState.setIsBackButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const onUserReportCancel = () => {
    if (isSendingUserReport) return;
    setReasonString("");
    setReasonArray([]);
    setUserReportDialog(false);
  };

  useEffect(() => {
    if (
      userData !== null &&
      userData !== undefined &&
      userData.id !== undefined &&
      userData.id !== null &&
      userData.id !== ""
    ) {
      let timeOutInit: any = null;

      const timeoutFunc = () => {
        timeOutInit = setTimeout(() => {
          logEvent(firebaseAnalytics, "user_visit", {
            user_uid: userData.id,
          });
        }, 3000);
      };
      timeoutFunc();
      return () => {
        clearTimeout(timeOutInit);
      };
    }
  }, [userData]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "30px",
        paddingTop: "100px",
        position: "absolute",
        top: "-10px",
        zIndex: 9999,
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    formGroup: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "0px",
    },
    checkBox: {
      "&.Mui-checked": {
        color: globalTheme.solidMainBlack.color,
      },
      color: globalTheme.solidMainBlack.color,
    },
    formControl: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      margin: '0px',
      width: '33%',
      "@media (max-width: 800px)": {
          width : '50%'
        },
      
      "& .MuiTypography-root": {
        fontSize: "11px !important",
        "@media (min-width: 350px)": {
          fontSize: "12px !important",
        },
        "@media (min-width: 550px)": {
          fontSize: "13px !important",
        },
        "@media (min-width: 700px)": {
          fontSize: "14px !important",
        },
      },
    },
    menuPaper: {
      "& .MuiPaper-root": {
        borderRadius: "8px",
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };


  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="Profile"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color, paddingTop: "80px" }}
    >
      {userData.name !== null && userData.name !== undefined && (
        <Helmet prioritizeSeoTags>
          <title>
            GATH3R -{" "}
            {userData.displayName === ""
              ? "Anonymous user"
              : userData.displayName}
          </title>
        </Helmet>
      )}
      <div
        className="ProfileHeader"
        style={{
          borderColor: globalTheme.solidMainBlack.color,
          position: "relative",
          boxShadow:
            deviceState.deviceWidth > 550
              ? globalTheme.boxShadow.boxShadow
              : "none",
          backgroundColor:
            deviceState.deviceWidth > 550
              ? globalTheme.backgroundMainBeigeDark.color
              : "transparent",
        }}
      >
        <div
          className="ProfileHeaderInner"
          style={
            {
              //border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            }
          }
        >
          <div
            className="ProfileAvatar"
            style={{
              border: `1px solid ${globalTheme.solidMainBlack.color}`,
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                className="ProfileAvatarImg"
                sx={{
                  height: "100%",
                  width: "100%",
                  marginBottom: "0px",
                  borderRadius: "8px",
                }}
              />
            ) : userData.pfpString === null ||
              userData.pfpString === undefined ||
              userData.pfpString === "" ? (
              <div className="ProfileAvatarImg">
                <PfpViewer pfpString={""} randomize={true} />
              </div>
            ) : (
              <div className="ProfileAvatarImg">
                <PfpViewer pfpString={userData.pfpString} randomize={false} />
              </div>
            )}
          </div>
          <div
            className="ProfileHeaderInnerText"
            style={
              {
                //border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
              }
            }
          >
            {isLoading ? (
              <Skeleton
                variant="text"
                style={{ fontSize: "20px", marginBottom: "5px", width: "90px" }}
              />
            ) : (
              <p
                className="UserPageUsername"
                style={{
                  color: globalTheme.backgroundMainOffBeigeDark.color,
                }}
              >
                {" "}
                {userData.displayName === ""
                  ? "Anonymous user"
                  : userData.displayName}
              </p>
            )}
            {isLoading ? (
              <Skeleton
                variant="text"
                style={{ fontSize: "12px", marginTop: "-5px", width: "60px" }}
              />
            ) : (
              <p
                className="UserPageLastActive"
                style={{
                  color: globalTheme.textMainGrey.color,
                }}
              >
                {" "}
                Active {moment(userData.lastActive).fromNow()}
              </p>
            )}
            {isLoading ? (
              <div className="UserPageEditLinkOuter">
                <Skeleton
                  variant="circular"
                  sx={{ height: "20px", width: "20px", margin: "5px" }}
                />
                <Skeleton
                  variant="circular"
                  sx={{ height: "20px", width: "20px", margin: "5px" }}
                />
              </div>
            ) : (
              <div
                className="UserPageEditLinkOuter"
                style={{ color: globalTheme.backgroundMainBeige.color }}
              >
                {user?.uid !== userId && (
                  <IconButton
                    disabled={isFollowingLoading}
                    size="small"
                    color="inherit"
                    onClick={onFollowClick}
                    sx={{
                      backgroundColor: globalTheme.backgroundMainOffBeige.color,
                      padding: "5px",
                      "&:hover": {
                        backgroundColor:
                          globalTheme.backgroundMainOffBeige.color,
                      },
                      margin: "3px",
                    }}
                  >
                    {isFollowing ? (
                      <PersonRemoveOutlinedIcon
                        sx={{
                          fontSize: "14px",
                          color: globalTheme.backgroundMainBeige.color,
                        }}
                      />
                    ) : (
                      <PersonAddAlt1OutlinedIcon
                        sx={{
                          fontSize: "14px",
                          color: globalTheme.backgroundMainBeige.color,
                        }}
                      />
                    )}
                  </IconButton>
                )}
                <IconButton
                  sx={{
                    margin: "3px",
                  }}
                  size="small"
                  color="inherit"
                  onClick={onShareClick}
                >
                  <ShareOutlinedIcon
                    sx={{
                      fontSize: "16px",
                      color: globalTheme.backgroundMainOffBeige.color,
                    }}
                  />
                </IconButton>
              </div>
            )}
            {isLoading ? (
              <Skeleton
                variant="text"
                style={{ fontSize: "15px", marginTop: "20px", width: "120px" }}
              />
            ) : (
              userData.about !== "" && (
                <p
                  className="ProfileAboutInnerText"
                  style={{
                    color: globalTheme.backgroundMainOffBeige.color,
                  }}
                >
                  {userData.about}
                </p>
              )
            )}
          </div>
        </div>
        {userId !== user?.uid && (
          <IconButton
            sx={{
              top: "0px",
              right: "0px",
              position: "absolute",
              "@media (min-width: 550px)": {
                top: "10px",
                right: "3px",
              },
            }}
            size="small"
            color="inherit"
            onClick={handleMenuClick}
          >
            <MoreVertOutlinedIcon
              sx={{
                fontSize: "20px",
                color: globalTheme.backgroundMainOffBeige.color,
                "@media (min-width: 350px)": {
                  fontSize: "22px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "24px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "26px",
                },
              }}
            />
          </IconButton>
        )}
      </div>
      <div className="ProfileCountOuter">
        <UserPageCount
          userId={userId}
          updateFollowers={isFollowingLoading}
          userPoints={userData.points}
          userListingCount={userData.listingCount}
          userReviewsCount={userData.reviewsCount}
          userRatingsCount={userData.ratingCount}
          followingCount={userData.followingCount}
          followersCount={userData.followersCount}
        />
      </div>
      {/*userData.privacy === false && userData.interests !== "" && (
            <div className="UserPageAbout">
              <div className="ProfileInterests">
                <div className="ProfileInterestsInner">
                  {userData.interests.split(",").map((interest: any) => (
                    <p
                      key={interest}
                      className="ProfileAboutInnerText"
                      style={{
                        color: globalTheme.figmaHighlight.color,
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {interest}
                    </p>
                  ))}
                </div>
              </div>
            </div>
                    )*/}
      <div className="ProfileAboutAchievements">
        <ProfileAchievementsSlider userId={userData.id} />
      </div>
      <div className="ProfileAbout" style={{ paddingTop: "0px" }}>
        <ProfileReviewPreview userId={userData.id} />
      </div>
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        sx={styles.menuPaper}
      >
        <MenuItem
          dense
          onClick={() => {
            setUserReportDialog(true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <ReportIcon
              fontSize="small"
              sx={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            />
          </ListItemIcon>
          <ListItemText>
            Report{" "}
            {userData.displayName === ""
              ? "Anonymous user"
              : userData.displayName}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={userReportDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onUserReportCancel}
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          },
        }}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={onUserReportCancel}
        >
          <CloseOutlined />
        </IconButton>
        <DialogTitle sx={{
            ...standardDialogTitle,
            width: "calc(100% - 8px)",
            marginLeft: "4px",
            lineHeight: "110%",
          }}>
          Report{" "}
          {userData.displayName === ""
            ? "User"
            : userData.displayName}
            <br />
          <span style={{ fontSize: "55%", fontWeight: 400 }}>
            State at least one reason to continue
          </span>
        </DialogTitle>
        <DialogContent>
          <FormGroup sx={styles.formGroup} row>
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"spam"}
                  checked={reasonArray.includes("spam")}
                  onChange={handleReasonChange}
                />
              }
              label="Spam"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"content"}
                  checked={reasonArray.includes("content")}
                  onChange={handleReasonChange}
                />
              }
              label="Inappropriate content"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"harassment"}
                  checked={reasonArray.includes("harassment")}
                  onChange={handleReasonChange}
                />
              }
              label="Harassment"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"imperonate"}
                  checked={reasonArray.includes("imperonate")}
                  onChange={handleReasonChange}
                />
              }
              label="Impersonation"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"falseInfo"}
                  checked={reasonArray.includes("falseInfo")}
                  onChange={handleReasonChange}
                />
              }
              label="False information"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"hate"}
                  checked={reasonArray.includes("hate")}
                  onChange={handleReasonChange}
                />
              }
              label="Hate speech"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"violence"}
                  checked={reasonArray.includes("violence")}
                  onChange={handleReasonChange}
                />
              }
              label="Violence"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"other"}
                  checked={reasonArray.includes("other")}
                  onChange={handleReasonChange}
                />
              }
              label="Other"
            />
          </FormGroup>
          <div className="AdminListDialogEditButtons">
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
                flex: "unset",
                minWidth: "90px",
              }}
              onClick={onUserReportCancel}
              disabled={isSendingUserReport}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                flex: "unset",
                marginLeft: "20px",
                minWidth: "90px",
              }}
              disabled={isSendingUserReport || reasonString === ""}
              onClick={onUserReportSubmit}
            >
              {isSendingUserReport ? "Submitting..." : "Submit"}
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
