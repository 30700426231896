import React, { useState, useEffect } from "react";

import { useThemeState } from "../../../components/utils/globalStates";
import "./ListingPageInfo.css";

export default function ListingPageInfo({ listing }: { listing: any }) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div className="ListingPageInfo">
      <p className="ListingPageInfoSub" style={{color: globalTheme.backgroundMainOffBeige.color}}>
             Coming soon...
            </p>
    </div>
  );
}
