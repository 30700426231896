import React, { useState, useEffect } from "react";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Reviews from "./routes/Reviews";
import ReviewsListing from "./routes/ReviewsListing";
import ReviewsUser from "./routes/ReviewsUser";
import RatingsUser from "./routes/RatingsUser";
import RatingsListing from "./routes/RatingsListing";

import "./ReviewStart.css";

export default function ReviewStart() {
  return (
    <div className="ReviewStart">
      <Routes>
        <Route path="/" element={<Reviews />} />
        <Route path="/listing/:listingId" element={<ReviewsListing />} />
        <Route path="/listing/rating/:listingId" element={<RatingsListing />} />
        <Route path="/user/:userId" element={<ReviewsUser />} />
        <Route path="/user/rating/:userId" element={<RatingsUser />} />
        <Route path="*" element={<Reviews />} />
      </Routes>
    </div>
  );
}
