import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Rating from "@mui/material/Rating";
import { useNavigate, Link } from "react-router-dom";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import { doc, getDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import IconButton from "@mui/material/IconButton";

import {
  useThemeState,
  useSnackbarState,
} from "../../../components/utils/globalStates";
import Pfp from "../../../components/ui/img/Pfp";
import { PfpViewer } from "../../../components/ui/img/PfpViewer";
import copy from "copy-to-clipboard";
import "./ReviewsComp.css";

moment().format();

export default function RatingListItem({
  reviewItem,
  isListing,
  isUser,
}: {
  reviewItem: any;
  isListing: boolean;
  isUser: boolean;
}) {
  const [authorData, setAuthorData] = useState<any | null>(null);
  const [authorLoading, setAuthorLoading] = useState(true);
  const [listingData, setListingData] = useState<any | null>(null);
  const [listingLoading, setListingLoading] = useState(true);
  const snackbarStates = useSnackbarState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const onShareClick = () => {
    copy(`https://app.gath3r.co/reviews/listing/${reviewItem.listing}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  // Get author
  useEffect(() => {
    if (isUser) {
      setAuthorLoading(false);
      return;
    }
    if (
      reviewItem.uid === null ||
      reviewItem.uid === undefined ||
      reviewItem.uid === ""
    ) {
      return;
    }
    const getData = async () => {
      try {
        const userDoc = await getDoc(
          doc(firestoreDb, "publicUserInfo", reviewItem.uid)
        );
        if (userDoc.exists()) {
          if (
            userDoc.data().displayName !== undefined &&
            userDoc.data().displayName !== null
          ) {
            setAuthorData(userDoc.data());
            setAuthorLoading(false);
          } else {
            setAuthorData(null);
            setAuthorLoading(false);
          }
        } else {
          setAuthorData(null);
          setAuthorLoading(false);
        }
      } catch (error) {
        console.error("Error getting author name:", error);
        setAuthorData(null);
        setAuthorLoading(false);
      }
    };
    getData();
  }, [reviewItem.uid]);

   // Get listing if is user
  useEffect(() => {
    if (isListing) {
      setListingLoading(false);
      return;
    }
    if (
      reviewItem.listing === null ||
      reviewItem.listing === undefined ||
      reviewItem.listing === ""
    ) {
      return;
    }
    const getData = async () => {
      try {
        const listingDoc = await getDoc(
          doc(firestoreDb, "listings", reviewItem.listing)
        );
        if (listingDoc.exists()) {
          if (
            listingDoc.data().name !== undefined &&
            listingDoc.data().name !== null
          ) {
            setListingData(listingDoc.data());
            setListingLoading(false);
          } else {
            setListingData(null);
            setListingLoading(false);
          }
        } else {
          setListingData(null);
          setListingLoading(false);
        }
      } catch (error) {
        console.error("Error getting listing name:", error);
        setListingData(null);
        setListingLoading(false);
      }
    };
    getData();
  }, [reviewItem.listing]);

  return (
    <div
      className="UserReviewsItem"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        boxShadow: globalTheme.boxShadow.boxShadow,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
      }}
    >
      {isListing &&
        (authorLoading ? (
          <div
            className="ReviewsItemUserHeader"
            style={{  }}
          >
            <Skeleton
              variant="circular"
              style={{ borderRadius: "6px", width: "30px", height: "30px" }}
            />
            <div className="ReviewsItemUserHeaderInner">
              <Skeleton
                variant="text"
                style={{ fontSize: "14px", marginBottom: "0px", width: "50px" }}
              />
              <Skeleton
                variant="text"
                style={{ fontSize: "11px", marginBottom: "0px", width: "80px" }}
              />
            </div>
          </div>
        ) : (
          authorData !== null && (
            <div
              className="ReviewsItemUserHeader"
              style={{  }}
            >
              <div className="ReviewsItemUserHeaderAvatar">
                {authorData.pfpString === null ||
                authorData.pfpString === undefined ||
                authorData.pfpString === "" ? (
                  <div className="ReviewsItemUserHeaderAvatarImg">
                    <PfpViewer pfpString={""} randomize={true} />
                  </div>
                ) : (
                  <div className="ReviewsItemUserHeaderAvatarImg">
                    <PfpViewer
                      pfpString={authorData.pfpString}
                      randomize={false}
                    />
                  </div>
                )}
              </div>
              <div className="ReviewsItemUserHeaderInner">
                <Link
                  to={`/users/page/${reviewItem.uid}`}
                  className="ReviewsItemUserHeaderInnerName"
                  style={{ color: globalTheme.textMainBlack.color }}
                >
                  {authorData.displayName === ""
                    ? "Anonymous user"
                    : authorData.displayName}
                </Link>
                <p
                  className="ReviewsItemUserHeaderInnerSub"
                  style={{ color: globalTheme.textMainGrey.color }}
                >
                  {authorData.reviewsCount !== undefined &&
                    authorData.reviewsCount !== null &&
                    authorData.reviewsCount > 0 && (
                      <Link
                        to={`/reviews/user/${reviewItem.uid}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {authorData.reviewsCount} reviews
                      </Link>
                    )}
                  {authorData.reviewsCount !== undefined &&
                    authorData.reviewsCount !== null &&
                    authorData.reviewsCount > 0 &&
                    authorData.ratingCount !== undefined &&
                    authorData.ratingCount !== null &&
                    authorData.ratingCount > 0 && <span> - </span>}
                  {authorData.ratingCount !== undefined &&
                    authorData.ratingCount !== null &&
                    authorData.ratingCount > 0 && (
                      <Link
                        to={`/reviews/user/rating/${reviewItem.uid}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {authorData.ratingCount} ratings
                      </Link>
                    )}
                </p>
              </div>
            </div>
          )
        ))}
        {isUser &&
        (listingLoading ? (
          <div
            className="ReviewsItemUserHeader"
          >
            <Skeleton
              variant="circular"
              style={{ borderRadius: "6px", width: "30px", height: "30px" }}
            />
            <div className="ReviewsItemUserHeaderInner">
              <Skeleton
                variant="text"
                style={{ fontSize: "14px", marginBottom: "0px", width: "50px" }}
              />
              <Skeleton
                variant="text"
                style={{ fontSize: "11px", marginBottom: "0px", width: "80px" }}
              />
            </div>
          </div>
        ) : (
          listingData !== null && (
            <div
              className="ReviewsItemUserHeader"
            >
              <div className="ReviewsItemUserHeaderAvatar">
                {listingData.logo !== null &&
                listingData.logo !== undefined &&
                listingData.logo !== "" ? (
                  <img
                    className="ReviewsItemUserHeaderAvatarImg"
                    src={listingData.logo}
                    alt="Profile"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: globalTheme.boxBackgroundWhite.color,
                      color: globalTheme.textMainBlack.color,
                      width: "65px",
                      height: "65px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      "@media (min-width: 350px)": {
                        width: "75px",
                        height: "75px",
                        fontSize: "17px",
                      },
                      "@media (min-width: 550px)": {
                        width: "85px",
                        height: "85px",
                        fontSize: "18px",
                      },
                      "@media (min-width: 700px)": {
                        width: "95px",
                        height: "95px",
                        fontSize: "20px",
                      },
                    }}
                    alt={listingData.name}
                  >
                    {listingData.name.substr(0, 2)}
                  </Avatar>
                )}
              </div>
              <div className="ReviewsItemUserHeaderInner">
                <Link
                  to={`/listings/page/${reviewItem.listing}`}
                  className="ReviewsItemUserHeaderInnerName"
                  style={{ color: globalTheme.textMainBlack.color }}
                >
                  {listingData.name !== null &&
                    listingData.name !== undefined &&
                    listingData.name !== "" &&
                    listingData.name}
                </Link>
                <p
                  className="ReviewsItemUserHeaderInnerSub"
                  style={{ color: globalTheme.textMainGrey.color }}
                >
                  {listingData.reviewsCount !== undefined &&
                    listingData.reviewsCount !== null &&
                    listingData.reviewsCount > 0 && (
                      <Link
                        to={`/reviews/listing/${reviewItem.listing}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {listingData.reviewsCount} reviews
                      </Link>
                    )}
                  {listingData.reviewsCount !== undefined &&
                    listingData.reviewsCount !== null &&
                    listingData.reviewsCount > 0 &&
                    listingData.ratingCount !== undefined &&
                    listingData.ratingCount !== null &&
                    listingData.ratingCount > 0 && <span> - </span>}
                  {listingData.ratingCount !== undefined &&
                    listingData.ratingCount !== null &&
                    listingData.ratingCount > 0 && (
                      <Link
                        to={`/reviews/listing/rating/${reviewItem.listing}`}
                        style={{
                          textDecoration: "none",
                          color: globalTheme.textMainGrey.color,
                        }}
                      >
                        {listingData.ratingCount} ratings
                      </Link>
                    )}
                </p>
              </div>
            </div>
          )
        ))}
      <div
        className="RatingItemInner"
        style={{ marginTop: isUser ? "-7px" : "0px"}}
      >
        <p className="UserReviewsItemTitle" style={{ marginBottom: "5px" }}>
            <span style={{ color: globalTheme.textMainBlack.color }}>
              Rated
            </span>{" "}
            <Link
              to={`/listings/page/${reviewItem.listing}`}
              style={{
                textDecoration: "none",
                color: globalTheme.figmaLinks.color,
              }}
            >
              {reviewItem.listingName}
            </Link>{" "}
            <span style={{ color: globalTheme.textMainBlack.color }}>
              {reviewItem.rating} stars
            </span>
            <Rating
              value={reviewItem.rating}
              readOnly
              size="small"
              sx={{
                transform: "scale(0.7) translateY(7px) translateX(-8px)",
                transition: "all 0.5s ease-in-out",
                "@media (min-width: 350px)": {
                    transform: "scale(0.8) translateY(6px) translateX(-6px)",
                  },
                  "@media (min-width: 550px)": {
                    transform: "scale(0.7) translateY(7px) translateX(-8px)",
                  },
                  "@media (min-width: 700px)": {
                    transform: "scale(0.7) translateY(7px) translateX(-8px)",
                  },
              }}
            />
          </p>
        <p
          className="ReviewsItemUserHeaderInnerSub"
          style={{
            color: globalTheme.textMainGrey.color,
            margin: "0px",
            marginTop: "2px",
            width: "unset",
            fontWeight: 300,
          }}
        >
          {moment(reviewItem.createdAt).fromNow()}
        </p>
      </div>

      <div
        className="UserRatingItemBottom"
        style={{ }}
      >
        <Link
          to={`/reviews/listing/${reviewItem.listing}`}
          style={{
            textDecoration: "none",
            color: globalTheme.textMainGrey.color,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginLeft: "0px",
            marginRight: "6px",
            textAlign: "left",
            flex: 1,
          }}
        >
          <RateReviewOutlinedIcon
            sx={{
              color: globalTheme.textMainGrey.color,
              fontSize: "13px",
              "@media (min-width: 350px)": {
                fontSize: "15px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "21px",
              },
            }}
          />
          <p
            className="UserRatingItemBottomText"
            style={{
              textDecoration: "none",
              textAlign: "left",
              color: globalTheme.textMainGrey.color
            }}
          >
            Reviews & ratings for {reviewItem.listingName}
          </p>
        </Link>
        <IconButton
              sx={{
                margin: 0,
                padding: 0,
                color: globalTheme.textMainGrey.color,
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon
                sx={{
                  fontSize: "13px",
              "@media (min-width: 350px)": {
                fontSize: "15px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "21px",
              },
                }}
              />
            </IconButton>
      </div>
    </div>
  );
}
